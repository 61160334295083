import styled from 'styled-components';

export const PickCarPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: #f1f0f2;

  .no-car-msg {
    background: white;
    padding: 32px 16px;
    border-radius: 20px;
    text-align: center;

    .add-car-btn {
      display: block;
      margin: 20px auto 0px auto;
      background: #10e4e4e4;
      border: 1px solid #0000002b;
      padding: 5px 16px;
      border-radius: 33px;
    }
  }

  .submit_button {
    margin-top: 60px;
  }
  .ant-form-item-label {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
  }

  button {
    &.ant-btn-primary {
      background: #10e4e4;
      color: #181726;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      height: 52px;
      border-radiu: 52px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      border: 0;
      &:hover {
        color: #10e4e4;
        background: #181726;
      }
    }
    &.ant-btn-round {
      background: #10e4e4;
      color: #181726;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      height: 52px;
      border-radiu: 52px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      border: 0;
      &:hover {
        color: #10e4e4;
        background: #181726;
      }
    }
  }
  .ant-form-item {
    background: #fff;
    padding: 20px 40px 30px;
    border-radius: 20px;
    margin-bottom: 15px;
    input {
      border-radius: 19px;
      border: 1px solid #707070;
      font-size: 12px;
      line-height: 20px;
      height: 37px !important;
      &:focus {
        box-shadow: none;
        border-color: #10e4e4;
      }
    }
    .ant-select-selector {
      border-radius: 19px;
      border: 1px solid #707070;
      font-size: 12px;
      line-height: 20px;
      height: 39px !important;
    }
    &.submit_button {
      background: transparent;
      margin-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    label {
      color: #181726;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      height: auto;
      margin-bottom: 0px;
    }
    button {
      background: #10e4e4;
      color: #181726;
      font-weight: 500;
      font-size: 14px;
      line-height: 26px;
      height: 52px;
      border-radiu: 52px;
      width: 100%;
      text-align: center;
      text-transform: uppercase;
      border: 0;
    }
  }

  &.paddingButtom {
    padding-bottom: 50px;
  }
`;
export const ButtonWrapper = styled.div`
  position: absolute;
  bottom: calc(64px + 16px);
  width: 100%;
  display: flex;
  left: 0;
  justify-content: center;
  padding: 0 15px;
  button {
    width: 250px;
  }
`;
export const ButtonWrapperIR = styled.div`
  position: absolute;
  bottom: calc(64px + 16px);
  width: 100%;
  display: flex;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    max-width: 250px;
    margin-bottom: 16px;
  }
`;
export const ButtonAddCarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  padding: 5px 15px;
  top: 20px;
`;
export const LoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: calc(100% - 64px);
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const SpinWrapper = styled.div`
  height: calc(100% - 64px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FormContent = styled.div`
  padding: 32px 24px;
`;
