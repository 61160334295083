import styled from "styled-components";

export const LoaderWrapper = styled.div`
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: #fff;
  flex-direction: column;
`;
