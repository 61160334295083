import React from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderSteps, ListItem } from '../../components';
import {
  MyDocumentsPageWrapper,
  MyDocumentsPageContent,
} from './my-documents.style';

export default function MyDocumentsPage() {
  const history = useHistory();
  return (
    <MyDocumentsPageWrapper className='MyDocumentsPageWrapper'>
      <HeaderSteps
        title='Documentele mele'
        subtitle='Totul într-un singur loc'
      />

      <MyDocumentsPageContent className='MyDocumentsPageContent'>
        <ListItem title='CI' action={() => history.push('/my-documents/ci')} />
        <ListItem
          title='Permis'
          action={() => history.push('/my-documents/permis')}
        />
      </MyDocumentsPageContent>
    </MyDocumentsPageWrapper>
  );
}
