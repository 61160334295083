import styled from "styled-components";

export const StepSummaryWrapper = styled.div`
  background: #f2f2f2;
  height: calc(100% - 64px);
  overflow: scroll;

  .step-title {
    margin-bottom: 8px;
    display: block;
    font-weight: bold;
  }

  .TableWrapper {
    margin-bottom: 20px;
  }
  .CarouselWrapper {
    padding: 0px;
  }
`;

export const StepSummaryContent = styled.div`
  padding: 32px;
`;

export const PhotoWrapper = styled.div`
  margin: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
  border-radius: 8px;
  background: #f2f2f2;
`;
