import React, { useContext } from "react";
import { AppContext } from "../../context/app.context";
import { useHistory, Redirect } from "react-router-dom";
import { Form, Input } from "antd";

import { ForgotPasswordPageWrapper } from "./forgot-password.style";
import { Button, Logo } from "../../components";

export default function ForgotPasswordPage() {
  const { user, forgotPassword, isLoading } = useContext(AppContext);
  const history = useHistory();

  const onFinish = (formData) => {
    forgotPassword(formData);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (user) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <ForgotPasswordPageWrapper>
      <Logo />

      <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item name="email_or_phone" rules={[{ required: true, message: "Adauga email sau telefon!" }]}>
          <Input placeholder="Email / Telefon" />
        </Form.Item>

        <Form.Item>
          <Button title="Am uitat parola" className="primary" loading={isLoading} disabled={isLoading} htmlType="submit" />
        </Form.Item>
      </Form>

      <Button className="info" title="Am deja cont" onClick={() => history.push("/login")} />
    </ForgotPasswordPageWrapper>
  );
}
