import styled from 'styled-components';

export const LegalPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;

  .HeaderWrapper {
    border: 1px solid #eee;
    border-top: none;
  }
`;
export const LegalPageContent = styled.div`
  padding: 22px 42px;
`;
