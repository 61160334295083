import React, { useContext, useState, useEffect } from "react";
import { HeaderSteps, Button, Uploader, SelectRadio, Loader, DatePickerWeb } from "../../../../components";
import { CiSubPageWrapper, CiSubPageContent } from "./ci.style";
import { AppContext } from "../../../../context/app.context";
import { useHistory } from "react-router-dom";

export default function CiSubpage() {
  const { getUserDocuments, saveUserDocuments, user } = useContext(AppContext);

  const [document, setDocument] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageLoaded, setPageLoaded] = useState(false);

  const [alert, setAlert] = useState(false);
  const history = useHistory();
  const fetchDocument = async () => {
    const res = await getUserDocuments("ci");

    if (res.error) {
      return;
    }

    setDocument(res.data.document);

    setAlert(res.data.alert);
    setEndDate(res.data.end_date);
    setPageLoaded(true);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: "ci",
      id_user: user.user.id,
      document: document,
      end_date: endDate,
      alert: alert,
    });

    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }
  return (
    <CiSubPageWrapper className="CiSubPageWrapper">
      <HeaderSteps title="Ci" subtitle="Documentele mele" />

      <CiSubPageContent className="CiSubPageContent">
        <Uploader label="Document CI" document={document} setDocument={setDocument} />

        <DatePickerWeb label="Dată expirare:" value={endDate} setValue={setEndDate} />

        <SelectRadio
          label="Alertă"
          onTextChange={(e) => {}}
          keyValue="value"
          keyName="label"
          items={[
            { label: "Da", value: true },
            { label: "Nu", value: false },
          ]}
          defaultValue={alert}
          placeholder="Vrei să primești o alertă când expiră?"
          onItemSelect={(item) => {
            setAlert(item.value);
          }}
        />

        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </CiSubPageContent>
    </CiSubPageWrapper>
  );
}
