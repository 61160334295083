import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Button, message } from "antd";
import { AppContext } from "../../context/app.context";
import { MultipleImagesPreview } from "../../components";

export default function DeveloperRoom() {
  let history = useHistory();
  const { setUser } = useContext(AppContext);

  const clearAll = () => {
    localStorage.clear();
    setUser(null);
  };
  return (
    <div style={{ backgroundColor: "#f2f2f2", padding: 32 }}>
      <Button block shape="round" onClick={() => history.push("/")}>
        HOME
      </Button>
      <Button block shape="round" onClick={() => history.push("/step-map-2d")}>
        Step map 2D
      </Button>
      <Button block shape="round" onClick={() => history.push("/step-custom")}>
        Step custom
      </Button>
      <Button block shape="round" onClick={() => message.success("400", 500000)}>
        aaaa
      </Button>

      <MultipleImagesPreview
        key={"1"}
        label={`Poliță de asigurare`}
        photosList={[{ label: "ceva", value: null }]}
        onHandleSave={(newPhotos) => {
          // setInsurancePolicyPhoto(newPhotos);
        }}
      />

      <Button onClick={clearAll}>Sterge TOT</Button>
    </div>
  );
}
