import React, {
  useContext, useEffect, useRef, useState 
} from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Spin, Carousel } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  Map2DWrapper,
  CarMapWrapper,
  PreviewPhotoWrapper,
  PreviewPhotoHeader,
  CarouselWrapper,
  CommentTag,
  ConfirmationActionWrapper,
  ButtonWrapper,
  ImageWrapper,
  IconTitleWrapper,
  CameraTitleWrapper,
  PreviewPhotoHeaderInfo,
  ConfirmationPreviewWrapper,
  CameraSubTitleWrapper,
} from './step-map-2d.styled';
import { CommentsCamara, HeaderSteps, Button } from '../../../../components';
import { AppContext } from '../../../../context/app.context';
import { storageSave, storageGet } from '../../../../storage/localStorage';

const searchStep = (array, step) => {
  try {
    const x = array.find((x) => x.step === step);
    return x;
  } catch (e) {}
};

export default function StepMap2D() {
  const {
    user, getCupiTemplate, currentMap2DObject, futureTask, setCurrentMap2DObject, isLoading 
  } = useContext(AppContext);

  const history = useHistory();
  const carImageRef = useRef(null);
  const [shouldRenderMap2D, setShouldRenderMap2D] = useState(false);
  const [currentPart, setCurrentPart] = useState(null);
  const [previewPhotoVisible, setPreviewPhotoVisible] = useState(false);

  const [camaraVisible, setCamaraVisible] = useState(null);
  const [userPos, setUserPos] = useState({ longitude: null, latitude: null });
  const [isAnsamblu, setIsAnsamblu] = useState(true);
  const [index, setIndex] = useState(0);
  const [retakePicture, setRetakePicture] = useState(false);

  const customTemplate = storageGet('customTemplate');
  const [step, setStep] = useState(storageGet('currentStep')); // route.params.step
  const [template, setTemplate] = useState(searchStep(customTemplate.form, step));

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((e) => setUserPos(e.coords));
  }, []);

  useEffect(() => {
    const carTypeID = futureTask?.car?.type_id || storageGet('pickedCar').type_id;

    if (!currentMap2DObject) {
      getCupiTemplate(user.jwt, carTypeID);
    }
  }, []);

  useEffect(() => {
    if (carImageRef.current) {
      setShouldRenderMap2D(true);
    }
  }, [carImageRef.current]);

  if (!currentMap2DObject) {
    return (
      <div
        style={{
          height: 'calc(100% - 64px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
  const { templates, carImage } = currentMap2DObject;

  const preparePoints = (points) => {
    const { clientHeight, clientWidth } = carImageRef.current;
    if (!points) return;
    const data = points.split(' ');
    let result = '';
    data.forEach((coord) => {
      const point = coord.split(',');
      const x = +parseFloat(clientWidth / parseFloat(point[0]).toFixed(4)).toFixed(0);
      const y = +parseFloat(clientHeight / parseFloat(point[1]).toFixed(4)).toFixed(0);
      result += `${x}, ${y} `;
    });
    return result;
  };

  const handlePolygonClick = (polygon) => {
    setCurrentPart(polygon);
    if (polygon.photos.length && polygon.photos[0].value) {
      setPreviewPhotoVisible(true);
    } else {
      setCamaraVisible(true);
    }
  };

  const renderMap2D = () => (
    <svg>
      {templates
          && templates.map((polygon) => {
            if (!polygon.photos) {
              polygon.photos = [];
            }
            return (
              <polygon
                onClick={() => {
                  handlePolygonClick(polygon);
                }}
                opacity={polygon.photos.length && polygon.photos[0].value ? 0.6 : 0.1}
                key={polygon.cupi_id}
                points={preparePoints(polygon.points)}
              />
            );
          })}
    </svg>
  );

  const renderCarImage = () => <img ref={carImageRef} src={carImage} alt="masina 2D" />;

  if (isLoading) {
    return (
      <div
        style={{
          height: 'calc(100% - 64px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const setPhotoInTemplate = (photoURI) => {
    const futureMap2DObject = currentMap2DObject;

    let partIndex;
    futureMap2DObject.templates.map((part, index) => {
      if (part.cupi_id == currentPart.cupi_id) {
        partIndex = index;
      }
    });
    const object = {};
    object.value = {};
    object.value.base64 = photoURI;
    object.comments = currentPart.comments;
    if (!futureMap2DObject.templates[partIndex].photos.length) {
      futureMap2DObject.templates[partIndex].photos = [];
      object.label = 'Ansamblu';
      setIsAnsamblu(false);
    } else if (futureMap2DObject.templates[partIndex].photos.length === 1) {
      object.label = 'Detaliu';
      setIsAnsamblu(true);
    } else if (isAnsamblu) {
      object.label = 'Ansamblu';
    } else {
      object.label = 'Detaliu';
    }
    const findElem = futureMap2DObject.templates[partIndex].photos.filter((photo) => photo.label === object.label);
    if (findElem.length) {
      let pIndex;
      futureMap2DObject.templates[partIndex].photos.map((part, index) => {
        if (part.label === findElem[0].label) {
          pIndex = index;
        }
      });
      if (object.value) {
        futureMap2DObject.templates[partIndex].photos[pIndex].value = object.value;
      }
      if (object.label) {
        futureMap2DObject.templates[partIndex].photos[pIndex].label = object.label;
      }
      if (object.comments) {
        futureMap2DObject.templates[partIndex].photos[pIndex].comments = object.comments;
      }
    } else {
      futureMap2DObject.templates[partIndex].photos.push(object);
    }

    setCurrentMap2DObject({ ...futureMap2DObject });
  };

  const deletePhotoFromTempalte = () => {
    const futureMap2DObject = currentMap2DObject;

    let partIndex;
    futureMap2DObject.templates.map((part, index) => {
      if (part.cupi_id == currentPart.cupi_id) {
        partIndex = index;
      }
    });

    futureMap2DObject.templates[partIndex].photos = [];
    setIsAnsamblu(true);
    setRetakePicture(false);
    setCurrentMap2DObject({ ...futureMap2DObject });
  };

  const renderAutoTemplatePhoto = (photo) => (
    <ImageWrapper>
      <img src={photo.value?.base64} alt="template masina" />

      <CommentTag
        style={{
          position: 'absolute',
          left: `${photo.comments.x}%`,
          top: `${photo.comments.y}%`,
        }}
      >
        {photo.comments.description}
      </CommentTag>
    </ImageWrapper>
  );

  const setRetakePhoto = () => {
    const futureMap2DObject = currentMap2DObject;

    let partIndex;
    futureMap2DObject.templates.map((part, index1) => {
      if (part.cupi_id === currentPart.cupi_id) {
        partIndex = index1;
      }
    });
    if (futureMap2DObject.templates[partIndex].photos.length) {
      setRetakePicture(futureMap2DObject.templates[partIndex].photos[index].label);
    }
  };

  const setAnsamblu = (index) => {
    const futureMap2DObject = currentMap2DObject;

    let partIndex;
    futureMap2DObject.templates.map((part, index1) => {
      if (part.cupi_id === currentPart.cupi_id) {
        partIndex = index1;
      }
    });
    if (futureMap2DObject.templates[partIndex].photos.length) {
      setRetakePicture(futureMap2DObject.templates[partIndex].photos[index].label);
    }
    if (futureMap2DObject.templates[partIndex].photos.length && futureMap2DObject.templates[partIndex].photos[index].label === 'Ansamblu') {
      setIsAnsamblu(true);
    } else if (futureMap2DObject.templates[partIndex].photos.length) {
      setIsAnsamblu(false);
    }
  };

  const renderPreviewPhoto = () => (
    <PreviewPhotoWrapper className="PreviewPhotoWrapper">
      <PreviewPhotoHeader className="PreviewPhotoHeader">
        <IconTitleWrapper className="IconTitleWrapper" onClick={() => setPreviewPhotoVisible(false)}>
          <CloseOutlined />
        </IconTitleWrapper>

        <PreviewPhotoHeaderInfo className="PreviewPhotoHeaderInfo">
          <CameraTitleWrapper className="camera-title">{currentPart.description}</CameraTitleWrapper>
          <CameraSubTitleWrapper>{isAnsamblu ? 'Poza de ansamblu' : 'Poza de detaliu'}</CameraSubTitleWrapper>
        </PreviewPhotoHeaderInfo>
      </PreviewPhotoHeader>
      <ConfirmationPreviewWrapper className="ConfirmationPreviewWrapper">
        <CarouselWrapper>
          <Carousel
            afterChange={(e) => {
              setIndex(e);
              setAnsamblu(e);
            }}
          >
            {currentPart.photos.map((photo) => renderAutoTemplatePhoto(photo))}
          </Carousel>
        </CarouselWrapper>
        <ConfirmationActionWrapper className="ConfirmationActionWrapper">
          <Button
            className="link-danger"
            onClick={() => {
              deletePhotoFromTempalte();
              setPreviewPhotoVisible(false);
            }}
            title="Sterge poza"
          />

          <Button
            className="retake-photo"
            onClick={() => {
              setRetakePhoto();
              setCurrentPart(currentPart);
              setCamaraVisible(true);
              setPreviewPhotoVisible(false);
            }}
            title="Refă poza"
          />
        </ConfirmationActionWrapper>
      </ConfirmationPreviewWrapper>
    </PreviewPhotoWrapper>
  );

  return (
    <Map2DWrapper className="Map2DWrapper">
      <HeaderSteps
        step={step}
        setStep={setStep}
        setTemplate={setTemplate}
        title={template && template.title}
        subtitle={template && template.subtitle}
        procent={(step * 100) / customTemplate.form.length}
      />

      {previewPhotoVisible && renderPreviewPhoto()}

      {camaraVisible && (
        <CommentsCamara
          title={currentPart.description}
          needComment
          carPart={currentPart}
          setPhotoURI={(photoURI) => setPhotoInTemplate(photoURI)}
          camaraVisible={camaraVisible}
          setCamaraVisible={setCamaraVisible}
          setPreviewPhotoVisible={setPreviewPhotoVisible}
          setImagePreviewFullScreenMode={(e) => console.log(e)}
          setCurrentPart={setCurrentPart}
          setIsAnsamblu={setIsAnsamblu}
          isAnsamblu={isAnsamblu}
          retakePicture={retakePicture}
          setRetakePicture={setRetakePicture}
        />
      )}
      <CarMapWrapper className="CarMapWrapper">
        <TransformWrapper scalePadding={{ size: 16 }}>
          {({
            zoomIn, zoomOut, resetTransform, ...rest 
          }) => (
            <TransformComponent>
              {renderCarImage()}
              {carImageRef.current && renderMap2D()}
            </TransformComponent>
          )}
        </TransformWrapper>
      </CarMapWrapper>

      <ButtonWrapper className="ButtonWrapper">
        <Button
          disabled={!currentMap2DObject?.templates.some((e) => e.photos?.length > 0)}
          onClick={() => {
            const index = customTemplate.form.findIndex((x) => x.step === template.step);
            customTemplate.form[index] = template;

            storageSave('customTemplate', customTemplate);
            setStep(template.nextStep);
            setTemplate(searchStep(customTemplate.form, template.nextStep));

            const nextTemplate = searchStep(customTemplate.form, template.nextStep);

            if (nextTemplate && nextTemplate.type === 'hardcoded') {
              if (nextTemplate.page === 'pick-car') {
                history.push({ pathname: '/step-pick-car' });
              }
              if (nextTemplate.page === '2D-map') {
                history.push({ pathname: '/step-map-2d' });
              }
              if (nextTemplate.page === 'summary') {
                history.push({ pathname: '/step-summary' });
              }
            } else {
              history.push({ pathname: `/step-custom/${template.nextStep}` });
            }
          }}
          className="primary"
          title="Urmatorul pas"
        />
      </ButtonWrapper>
    </Map2DWrapper>
  );
}
