/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { HeaderSteps, SelectRadio, Button, Loader, DatePickerWeb } from "../../../../components";
import { ExtinctorSubPageWrapper, ExtinctorSubPageContent } from "./extinctor.style";
import { AppContext } from "../../../../context/app.context";
import { useHistory } from "react-router-dom";

export default function ExtinctorSubpage() {
  const { user, getUserDocuments, saveUserDocuments, selectedCarFromGarage } = useContext(AppContext);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [endDate, setEndDate] = useState(null);

  const [alert, setAlert] = useState(false);
  const history = useHistory();

  const fetchDocument = async () => {
    const res = await getUserDocuments("extinctor", selectedCarFromGarage.id);

    if (res.error) {
      return;
    }

    setAlert(res.data.alert);
    setEndDate(res.data.end_date);

    setPageLoaded(true);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: "extinctor",
      id_user: user.user.id,
      end_date: endDate,
      alert: alert,
      car_id: selectedCarFromGarage.id,
    });

    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }

  return (
    <ExtinctorSubPageWrapper className="ExtinctorSubPageWrapper">
      <HeaderSteps title="Extinctor" subtitle={`Mașină ${selectedCarFromGarage.registration_number}`} />

      <ExtinctorSubPageContent className="ExtinctorSubPageContent">
        <DatePickerWeb label="Dată expirare:" value={endDate} setValue={setEndDate} />

        <SelectRadio
          label="Alertă"
          onTextChange={(e) => {}}
          keyValue="value"
          keyName="label"
          items={[
            { label: "Da", value: true },
            { label: "Nu", value: false },
          ]}
          defaultValue={alert}
          placeholder="Vrei să primești o alertă când expiră?"
          onItemSelect={(item) => {
            setAlert(item.value);
          }}
        />

        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </ExtinctorSubPageContent>
    </ExtinctorSubPageWrapper>
  );
}
