import styled from "styled-components";

export const ProfilePageWrapper = styled.div`
  background: #f1f0f2;
  height: calc(100% - 64px);
  overflow: scroll;

  h2 {
    font-size: 18;
  }

  .danger {
    margin-top: 20px;
    background: #ff6b6b;
    color: white;
}
  }
`;

export const ProfileInfoWrapper = styled.div`
  justify-content: center;
  display: flex;
  background: #fff;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 32px;
  border-bottom-left-radius: 80px;
  div {
    margin-top: 16px;
  }
  .LastNameUserClass {
    font-weight: bold;
    font-size: 18px;
    color: #181726;
    line-height: 30px;
  }
  .RoleUserClass {
    text-transform: uppercase;
    color: #767676;
    font-size: 12px;
  }
  .LogoutClass {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 30px;
    color: #181726;
    cursor: pointer;
  }
  .ant-avatar {
    width: 97px !important;
    height: 97px !important;
    line-height: 97px !important;
    border: 1px solid #10e4e4;
  }
`;
export const LogOutWrapper = styled.div`
  position: relative;
  left: 40%;
`;
export const ImageUpload = styled.div`
  input {
    display: none;
  }

  label {
    position: relative;
    top: -65px;
  }

  img {
    width: 80px;
    cursor: pointer;
  }
`;

export const IconProfileWrapper = styled.div`
  background: #10e4e4;
  position: relative;
  top: -46px;
  border-radius: 20px;
  right: -24px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

export const ContentWrapper = styled.div`
  padding: 10px 14px 32px 14px;
`;
