import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../context/app.context';
import righticonpng from '../../assets/gif/right-arrow-icon.png';

import {
  ContainerWrapper, CarListItemWrapper, CarInfo, CarNumberWrapper, CarMake, Information 
} from './car-list-item.style';

import { storageSave } from '../../storage/localStorage';

export default function CarListItem({ car, onClick }) {
  const { futureTask } = useContext(AppContext);
  const location = useLocation();

  return (
    <ContainerWrapper>
      <CarListItemWrapper
        onClick={() => {
          storageSave('pickedCar', car);
          onClick();
        }}
        className={`CarListItemWrapper ${futureTask.car.id === car.id && location.pathname === '/step-pick-car' ? 'active' : 'inactive'}`}
      >
        <CarInfo>
          <CarNumberWrapper className="CarNumberWrapper">{car.registration_number}</CarNumberWrapper>
        </CarInfo>
        <CarMake>{car.make}</CarMake>
        <Information>{car.model}</Information>
        <Information>{car.vin}</Information>
        <Information>
          IR | #
          {' '}
          {car.id}
        </Information>
        <Information>
          Vezi detalii
          <img src={righticonpng} alt="right icon" />
        </Information>
      </CarListItemWrapper>

      {car.avatar && <img className="car-image" alt="car avatar" src={car.avatar} />}
    </ContainerWrapper>
  );
}

CarListItem.propTypes = {
  car: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};
