// Utils
export const isEmpty = (obj) => Object.keys(obj).length === 0;

// Search step
export const searchStep = (array, step) => {
  try {
    const x = array.find((x) => x.step === step);
    return x;
  } catch (e) {}
};

export const getDateString = (date) => {
  let futureDate = new Date(date);

  const dd = String(futureDate.getDate()).padStart(2, '0');
  const mm = String(futureDate.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = futureDate.getFullYear();
  futureDate = `${dd}/${mm}/${yyyy}`;

  return futureDate;
};

export const getTimeString = (time) => {
  const newTime = new Date(time);
  const hours = newTime.getHours();
  let minutes = newTime.getMinutes();

  // Add extra 0 if time is 12:0
  if (minutes.toString().length === 1) {
    minutes += '0';
  }

  return `${hours}:${minutes}`;
};

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
}

export function getCookie(cname) {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

const browserVersion = (userAgent, regex) => (userAgent.match(regex) ? userAgent.match(regex)[2] : null);

export const getBrowser = () => {
  const userAgent = navigator.userAgent;
  let browser = 'unkown';
  // Detect browser name
  browser = /ucbrowser/i.test(userAgent) ? 'UCBrowser' : browser;
  browser = /edg/i.test(userAgent) ? 'Edge' : browser;
  browser = /googlebot/i.test(userAgent) ? 'GoogleBot' : browser;
  browser = /chromium/i.test(userAgent) ? 'Chromium' : browser;
  browser = /firefox|fxios/i.test(userAgent) && !/seamonkey/i.test(userAgent) ? 'Firefox' : browser;
  browser = /; msie|trident/i.test(userAgent) && !/ucbrowser/i.test(userAgent) ? 'IE' : browser;
  browser = /chrome|crios/i.test(userAgent) && !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(userAgent) ? 'Chrome' : browser;
  browser = /safari/i.test(userAgent) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i.test(userAgent) ? 'Safari' : browser;
  browser = /opr|opera/i.test(userAgent) ? 'Opera' : browser;

  // detect browser version
  switch (browser) {
    case 'UCBrowser':
      return `${browser}/${browserVersion(userAgent, /(ucbrowser)\/([\d\.]+)/i)}`;
    case 'Edge':
      return `${browser}/${browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
    case 'GoogleBot':
      return `${browser}/${browserVersion(userAgent, /(googlebot)\/([\d\.]+)/i)}`;
    case 'Chromium':
      return `${browser}/${browserVersion(userAgent, /(chromium)\/([\d\.]+)/i)}`;
    case 'Firefox':
      return `${browser}/${browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i)}`;
    case 'Chrome':
      return `${browser}/${browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i)}`;
    case 'Safari':
      return `${browser}/${browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
    case 'Opera':
      return `${browser}/${browserVersion(userAgent, /(opera|opr)\/([\d\.]+)/i)}`;
    case 'IE':
      const version = browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
      // IE version is mapped using trident version
      // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
      return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
    default:
      return 'unknown/0.0.0.0';
  }
};
export const isFile = (input) => 'File' in window && input instanceof File;
