import React, { useContext, useEffect, useState } from "react";
import { Button, DatePickerWeb, HeaderSteps, Loader } from "../../../../components";
import { ReviziiSubPageWrapper, ReviziiSubPageContent } from "./revizii.style";
import { AppContext } from "../../../../context/app.context";
import { useHistory } from "react-router-dom";

export default function ReviziiSubpage() {
  const { user, getUserDocuments, saveUserDocuments, selectedCarFromGarage } = useContext(AppContext);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const history = useHistory();

  const fetchDocument = async () => {
    const res = await getUserDocuments("revizii", selectedCarFromGarage.id);

    if (res.error) {
      return;
    }

    setStartDate(res.data.start_date);

    setPageLoaded(true);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: "revizii",
      id_user: user.user.id,
      start_date: startDate,
      car_id: selectedCarFromGarage.id,
    });

    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }
  return (
    <ReviziiSubPageWrapper className="ReviziiSubPageWrapper">
      <HeaderSteps title="Revizii" subtitle={`Mașină ${selectedCarFromGarage.registration_number}`} />

      <ReviziiSubPageContent className="ReviziiSubPageContent">
        <DatePickerWeb value={startDate} setValue={setStartDate} label="Dată început:" />

        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </ReviziiSubPageContent>
    </ReviziiSubPageWrapper>
  );
}
