import styled from "styled-components";

export const InputWrapper = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 22px 42px 32px 42px;

  width: 100%;

  .ant-input-affix-wrapper {
    height: 37px;
    border-radius: 33px;
    border: 1px solid #707070;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 16px;
  }
`;
