/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { message } from 'antd';
import { InputWeb, Logo, Button } from '../../components';
import { AppContext } from '../../context/app.context';
import { ResetPasswordWrapper } from './reset-password.style';
import { storageSave } from '../../storage/localStorage';

const getUrlParams = function () {
  const url = window.location.href;

  const params = {};
  `${url}?`
    .split('?')[1]
    .split('&')
    .forEach((pair) => {
      pair = `${pair}=`.split('=').map(decodeURIComponent);
      if (pair[0].length) {
        params[pair[0]] = pair[1];
      }
    });
  return params;
};

export default function ResetPasswordPage() {
  const {
    user, setUser, isLoading, API_URL, setIsLoading, getTasks, getMakes, getRegions 
  } = useContext(AppContext);

  const [newPass, setNewPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [canContinue, setcanContinue] = useState(false);
  const [finalMsg, setFinalMsg] = useState('Adaugă o parolă');
  const params = getUrlParams();

  // Try to open app
  useEffect(() => {
    if (params.expo) {
      window.location = `${params.expo}?reset_token=${params.reset_token}&env=${params.env || 'live'}`;
    } else if (window.location.origin.includes('test-webapp')) {
      window.location = `carfix://?reset_token=${params.reset_token}&env=${params.env || 'test'}`;
    } else {
      window.location = `carfix://?reset_token=${params.reset_token}&env=${params.env || 'live'}`;
    }
  }, []);

  // Effect on password / confirm input
  useEffect(() => {
    if (!newPass) {
      setFinalMsg('Adaugă o parolă');
      return setcanContinue(false);
    }

    if (newPass.length < 8) {
      setFinalMsg('Parola trebuie să conțină minim 8 caractere');
      return setcanContinue(false);
    }

    if (!confirmPass) {
      setFinalMsg('Confirmă parola');
      return setcanContinue(false);
    }

    if (newPass !== confirmPass) {
      setFinalMsg('Parolele nu conincid');
      return setcanContinue(false);
    }

    setFinalMsg('Schimbă parola');
    setcanContinue(true);
  }, [newPass, confirmPass]);

  const handleResetPassword = () => {
    setIsLoading(true);

    const bodyFormData = new FormData();
    bodyFormData.set('reset_token', params.reset_token);
    bodyFormData.set('password', newPass);

    return axios({
      method: 'post',
      url: `${API_URL}/auth/reset-pass`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        setUser(response.data);
        storageSave('user', response.data);
        message.success('Bun venit!');

        getTasks(response.data.jwt);
        getMakes(response.data.jwt, response.data.user.id);
        getRegions();

        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        setIsLoading(false);
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
        return false;
      });
  };

  if (user) {
    return <Redirect to="/dashboard" />;
  }

  if (!params.reset_token) {
    return <Redirect to="/" />;
  }

  return (
    <ResetPasswordWrapper>
      <Logo />

      <InputWeb
        allowClear
        placeholder="******"
        label="Parolă noua"
        text={newPass}
        autoCapitalize="characters"
        onChangeText={(e) => setNewPass(e)}
      />

      <InputWeb
        allowClear
        placeholder="******"
        label="Confirmă parola"
        text={confirmPass}
        autoCapitalize="characters"
        onChangeText={(e) => setConfirmPass(e)}
      />

      <Button isLoading={isLoading} onClick={handleResetPassword} disabled={!canContinue} className="primary" title={finalMsg} />
    </ResetPasswordWrapper>
  );
}
