import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { UserOutlined, FacebookOutlined, EditOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import { AppContext } from '../../context/app.context';
import usericonpng from '../../assets/gif/user-icon.png';
import notificonpng from '../../assets/gif/notif-icon.png';
import legaliconpng from '../../assets/gif/legal-icon.png';
import feebdbackiconpng from '../../assets/gif/feedback-icon.png';
import helpiconpng from '../../assets/gif/help-icon.png';
import settingsiconpng from '../../assets/gif/settings-icon.png';

import {
  SettingsPageWrapper,
  ProfileInfoWrapper,
  SectionnewWrapper,
  SectionWrapper,
  IconNotificationNumberWrapper,
  ImageUpload,
  IconProfileWrapper,
  LogOutWrapper,
} from './settings.style';

import { ListItem } from '../../components';

export default function SettingsPage() {
  const {
    user, signout, notifications, updateProfile 
  } = useContext(AppContext);
  const history = useHistory();
  const [notificatioNumber, setNotificationNumber] = useState(0);

  useEffect(() => {
    if (notifications) {
      setNotificationNumber(notifications.length);
    }
  }, [notifications]);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const file = event.target.files[0];
      reader.onloadend = () => {
        const formData = {
          email: user.user.email,
          Nume: user.user.firstname,
          Prenume: user.user.lastname,
          numar_telefon: user.user.phone,
          avatar: reader.result,
          userId: user.user.id,
        };

        updateProfile(formData);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <SettingsPageWrapper className="SettingsPageWrapper">
      <ProfileInfoWrapper className="ProfileInfoWrapper">
        <LogOutWrapper
          className="LogoutClass"
          onClick={() => {
            signout();
          }}
        >
          LOGOUT
        </LogOutWrapper>
        <Avatar src={user.profile?.avatar} size={75} icon={<UserOutlined />} />
        <span className="LastNameUserClass">
          Salut,
          {' '}
          {user.user.lastname}
          !
        </span>
        <span className="RoleUserClass">{user.role}</span>
        <ImageUpload>
          <label htmlFor="file-input">
            <IconProfileWrapper>
              <EditOutlined />
              {' '}
            </IconProfileWrapper>
          </label>
          <input id="file-input" onChange={onImageChange} type="file" name="file" accept="image/*" capture="camera" />
        </ImageUpload>
      </ProfileInfoWrapper>
      {/* Settings */}
      <SectionnewWrapper className="SectionnewWrapper">
        <h1>General</h1>
        <SectionWrapper className="SectionWrapper">
          <ListItem
            title="Profil"
            // subtitle='Configureaza-ti profilul'
            icon={<img src={usericonpng} alt="profil" />}
            action={() => history.push('/profile')}
          />

          <ListItem
            title="Legal"
            // subtitle='Documente legale'
            icon={<img src={legaliconpng} alt="document" />}
            action={() => history.push('/legal-documents')}
          />
          <ListItem
            title="Social"
            // subtitle='Lasa-ne un review'
            icon={<FacebookOutlined />}
            action={() => console.log('alo')}
          />
          <ListItem
            title="Feedback"
            // subtitle='Lasa-ne un review'
            icon={<img src={feebdbackiconpng} alt="smile" />}
            action={() => console.log('alo')}
          />
          <ListItem
            title="Settings"
            // subtitle='Lasa-ne un review'
            icon={<img src={settingsiconpng} alt="gear" />}
            action={() => {
              console.log('alo');
            }}
          />
          <ListItem
            title="Notifications"
            //  subtitle='Vezi toate notificarile'
            icon={(
              <div>
                <img src={notificonpng} alt="bell" /> 
                {' '}
                <IconNotificationNumberWrapper> 
                  {' '}
                  {notificatioNumber}
                  {' '}
                </IconNotificationNumberWrapper>
                {' '}
              </div>
            )}
            action={() => history.push('/notification')}
          />
          <ListItem
            title="Help"
            // subtitle='Lasa-ne un review'
            icon={<img src={helpiconpng} alt="help" />}
            action={() => console.log('alo')}
          />
        </SectionWrapper>
      </SectionnewWrapper>
      <div style={{ marginTop: 'auto', bottom: 16 }}>b12</div>
      {/* <ListItem
        title='Logout'
        subtitle='Delogheaza-te'
        icon={<LogoutOutlined />}
        action={() => signout()}
      /> */}
    </SettingsPageWrapper>
  );
}
