import React from 'react';

import { useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo-with-text.png'; 
import { ReactComponent as Illustration } from '../../assets/images/task.svg';
import {
  CompletedIRPageWrapper, LogoWrapper, IllustrationWrapper, TitleWrapper, ContactWrapper, TextWrapper, TaskInfo,
} from './completed-ir.styled';

export default function CompletedIR() {
  const location = useLocation();
  return (
    <CompletedIRPageWrapper className="CompletedIRPageWrapper">
      <LogoWrapper className="LogoWrapper">
        <img src={logo} alt="logo" />
      </LogoWrapper>

      <TaskInfo>
        <IllustrationWrapper>
          <Illustration />
        </IllustrationWrapper>
        <TitleWrapper>{location?.state?.title.toUpperCase()}</TitleWrapper>
        <TextWrapper>{location?.state?.vin}</TextWrapper>
        <TextWrapper>{`${location?.state?.status_label} ${location?.state?.status_text}`}</TextWrapper>
      </TaskInfo>
      <ContactWrapper>
        <TextWrapper>{location?.state?.contact}</TextWrapper>
        <TextWrapper><a href="tel:0755090504">0755090504</a></TextWrapper>
        <TextWrapper><a href="mailto:suport@carfixapp.com">suport@carfixapp.com</a></TextWrapper>
      </ContactWrapper>

    </CompletedIRPageWrapper>
  );
}
