import React, { useContext } from "react";
import { BellOutlined } from "@ant-design/icons";

import { useHistory } from "react-router-dom";
import { NotificatonWrapper, InfoWrapper } from "./notification.style";
import { AppContext } from "../../context/app.context";
import moment from "moment";

export default function Notification({ notification }) {
  const { handleExpireNotificationClick } = useContext(AppContext);
  const { message, id, title, created_at } = notification;
  const history = useHistory();

  const time = created_at ? moment(created_at) : null;

  return (
    <NotificatonWrapper
      onClick={() => {
        if (notification.data.category === "task") {
          history.push({ pathname: "/task", search: `?id=${id}` });
        } else {
          handleExpireNotificationClick(notification.data, history);
        }
      }}
    >
      <BellOutlined />
      <InfoWrapper>
        <h2>{title}</h2>
        <h3>{message}</h3>

        <div className="date">
          <span className="date-date">{time && time.format("DD-MM-YYYY")}</span>
          <span className="hour">{time && time.format("HH:mm")}</span>
        </div>
      </InfoWrapper>
    </NotificatonWrapper>
  );
}
