/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Select } from "antd";

import { SelectWrapper } from "./select.styled";

// App
export default function SelectWeb({
  label,
  onTextChange,
  onItemSelect,
  placeholder,
  defaultIndex,
  items,
  style,
  keyValue = "value",
  keyName = "name",
}) {
  const [localItems, setLocalItems] = useState(null);

  const [selectedValue, setSelectedValue] = useState(defaultIndex);

  const onSelectedOption = (value) => {
    const x = localItems.find((x) => x[keyValue] == value);
    setSelectedValue(x);

    if (x) {
      onItemSelect(x);
    }
  };

  useEffect(() => {
    let futureLocal = items.map((s) => {
      return { ...s, label: s[keyName], value: s[keyValue] };
    });

    setLocalItems(futureLocal);
  }, []);

  useEffect(() => {
    if (localItems) {
      if (defaultIndex) {
        onSelectedOption(selectedValue);
      }
    }
  }, [localItems]);

  return (
    <SelectWrapper style={style} className="SelectWrapper">
      {label && <label>{label}</label>}
      <Select
        defaultValue={defaultIndex?.toString()}
        placeholder={placeholder}
        allowClear
        // defaultValue={futureTask.stepInsurance.insurer ? futureTask.stepInsurance.insurer : null}
        showSearch
        onChange={onSelectedOption}
        filterOption={(input, option) => {
          return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {items.map((option) => (
          <Select.Option key={option[keyValue]} value={option[keyValue]}>
            {option[keyName]}
          </Select.Option>
        ))}
      </Select>
    </SelectWrapper>
  );
}
