/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { HeaderSteps, Button, SelectRadio, Uploader, Loader, DatePickerWeb } from "../../../../components";
import { RovinietaSubPageWrapper, RovinietaSubPageContent } from "./rovinieta.style";
import { AppContext } from "../../../../context/app.context";
import { useHistory } from "react-router-dom";

export default function RovinietaSubpage() {
  const { user, getUserDocuments, saveUserDocuments, selectedCarFromGarage } = useContext(AppContext);

  const [document, setDocument] = useState(null);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [alert, setAlert] = useState(false);
  const history = useHistory();

  const fetchDocument = async () => {
    const res = await getUserDocuments("rovinieta", selectedCarFromGarage.id);

    if (res.error) {
      return;
    }

    setDocument(res.data.document);

    setAlert(res.data.alert);
    setStartDate(res.data.start_date);
    setEndDate(res.data.end_date);

    setPageLoaded(true);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: "rovinieta",
      id_user: user.user.id,
      document: document,
      start_date: startDate,
      end_date: endDate,
      alert: alert,
      car_id: selectedCarFromGarage.id,
    });

    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }
  return (
    <RovinietaSubPageWrapper className="RovinietaSubPageWrapper">
      <HeaderSteps title="Rovinietă" subtitle={`Mașină ${selectedCarFromGarage.registration_number}`} />

      <RovinietaSubPageContent className="RovinietaSubPageContent">
        <Uploader label="Document rovinietă" document={document} setDocument={setDocument} />

        <DatePickerWeb label="Dată început:" setValue={setStartDate} value={startDate} />
        <DatePickerWeb label="Dată expirare:" setValue={setEndDate} value={endDate} />

        <SelectRadio
          label="Alertă"
          onTextChange={(e) => {}}
          keyValue="value"
          keyName="label"
          items={[
            { label: "Da", value: true },
            { label: "Nu", value: false },
          ]}
          defaultValue={alert}
          placeholder="Vrei să primești o alertă când expiră?"
          onItemSelect={(item) => {
            setAlert(item.value);
          }}
        />

        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </RovinietaSubPageContent>
    </RovinietaSubPageWrapper>
  );
}
