/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Affix } from "antd";
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";

import { AppContext } from "../../context/app.context";

import { TasksPageWrapper, TasksTopPageWrapper, TabsWrapper, TabWrapperButton, TabButton, TitleWrapper, IconWrapper } from "./tasks.style";

import ToBeValidateSubpage from "./subpages/to-be-validate/to-be-validate.subpage";
import NewSubpage from "./subpages/new/new.subpage";
import ClosedSubpage from "./subpages/closed/closed.subpage";

export default function TasksPage() {
  const history = useHistory();
  const location = useLocation();
  const { user, getTasks } = useContext(AppContext);

  useEffect(() => {
    getTasks(user.jwt);
  }, []);

  if (location.pathname === "/tasks") {
    return <Redirect to="/tasks/new" />;
  }

  return (
    <TasksPageWrapper id="TasksPageWrapper" className="TasksPageWrapper">
      <Affix>
        <TabsWrapper className="buttons-top">
          <TasksTopPageWrapper>
            <IconWrapper>
              <ArrowLeftOutlined onClick={() => history.goBack()} />
            </IconWrapper>
            <TitleWrapper>Solicitari</TitleWrapper>
          </TasksTopPageWrapper>
          <TabWrapperButton>
            <TabButton
              type={location.pathname === "/tasks/new" ? "primary" : "default"}
              onClick={() => {
                history.replace("/tasks/new");
              }}
            >
              Noi
            </TabButton>
            <TabButton
              type={location.pathname === "/tasks/to-be-validate" ? "primary" : "default"}
              onClick={() => {
                history.replace("/tasks/to-be-validate");
              }}
            >
              In lucru
            </TabButton>
            <TabButton
              type={location.pathname === "/tasks/closed" ? "primary" : "default"}
              onClick={() => {
                history.replace("/tasks/closed");
              }}
            >
              Inchise
            </TabButton>
          </TabWrapperButton>
        </TabsWrapper>
      </Affix>

      <Switch>
        <Route path="/tasks/new">
          <NewSubpage />
        </Route>
        <Route path="/tasks/closed">
          <ClosedSubpage />
        </Route>
        <Route path="/tasks/to-be-validate">
          <ToBeValidateSubpage />
        </Route>
      </Switch>
    </TasksPageWrapper>
  );
}
