/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect, useState, useRef, useContext 
} from 'react';

import { useLocation, useHistory } from 'react-router-dom';
import {
  Spin, Button, Input, message 
} from 'antd';
import PDFViewer from 'mgr-pdf-viewer-react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import {
  DocumentPageWrapper, PDFWrapper, ButtonWrapper, ButtonsWrapper, SignatureWrapper, IbanInputWrapper 
} from './document.styled';
import { FancyHeader } from '../../components';
import { AppContext } from '../../context/app.context';
import { storageGet, storageSave } from '../../storage/localStorage';

export default function DocumentPage() {
  const location = useLocation();
  const [document, setDocument] = useState(null);
  const [signaturePadVisible, setSignaturePadVisible] = useState(false);
  const signatureRef = useRef(null);
  const [startedSigning, setStartedSigning] = useState(false);
  const [iban, setIban] = useState('');
  const [valideIban, setValideIban] = useState(false);
  const {
    setIsLoading, user, currentDetailTask, API_URL 
  } = useContext(AppContext);

  const history = useHistory();

  const [canContinue, setCanContinue] = useState(false);

  useEffect(() => {
    if (location.state && location.state.document) {
      setDocument(location.state.document);

      storageSave('lastDocument', location.state.document);
    } else {
      setDocument(storageGet('lastDocument'));
    }
  }, []);

  useEffect(() => {
    if (
      document?.filename === 'NotaConstatareRCA'
      || (document?.filename === 'NotaConstatareCASCO' && startedSigning)
      || (document?.filename === 'RisqueInspectionReport' && startedSigning)
      || (document?.filename === 'proces_verbal_constatare' && startedSigning)
      || (document?.filename === 'oferta_de_despagubire' && startedSigning)
    ) {
      return setCanContinue(true);
    } 
    if (startedSigning && iban && valideIban) {
      return setCanContinue(true);
    }
    return setCanContinue(false);
  }, [startedSigning, valideIban]);

  useEffect(() => {
    const regex = /RO[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([a-zA-Z0-9]{4}\s?){4}\s?/;
    const valide = regex.test(iban);

    if (valide && iban.replace(/ /g, '').length === 24) {
      setValideIban(true);
    } else {
      setValideIban(false);
    }
  }, [iban]);

  if (!document) {
    return (
      <div
        style={{
          height: 'calc(100% - 64px)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const signDocument = () => {
    const signatureBase64 = signatureRef.current.toDataURL();

    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.set('file', `${document.filename}.${document.extension}`);
    bodyFormData.set('iban', iban);
    bodyFormData.set('signature', signatureBase64);
    bodyFormData.set('task_id', currentDetailTask.task_id);

    axios({
      method: 'post',
      url: `${API_URL}/task/signdocument`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setIsLoading(false);
        history.goBack();
        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
        setIsLoading(false);
      });
  };

  const canRenderIban = () => {
    if (
      document.filename === 'NotaConstatareRCA'
      || document.filename === 'NotaConstatareCASCO'
      || document.filename === 'RisqueInspectionReport'
      || document.filename === 'proces_verbal_constatare'
      || document.filename === 'oferta_de_despagubire'
    ) {
      return false;
    }

    return true;
  };

  const renderSignaturePad = () => (
    <DocumentPageWrapper>
      <SignatureWrapper>
        <FancyHeader title="Semnează documentul" subtitle={document.name} />
        {canRenderIban() && (
          <IbanInputWrapper>
            <Input size="large" placeholder="RO49 AAAA 1B31 0075 9384 0999" value={iban} onChange={(e) => setIban(e.target.value)} />
            {iban && !valideIban && <div className="error-message">Iban invalid</div>}
          </IbanInputWrapper>
        )}

        <SignatureCanvas
          onEnd={() => setStartedSigning(true)}
          ref={signatureRef}
          penColor="black"
          canvasProps={{ width: 300, height: 300, className: 'sigCanvas' }}
        />

        <ButtonsWrapper>
          <Button
            block
            shape="round"
            onClick={() => {
              setStartedSigning(false);
              signatureRef.current.clear();
            }}
          >
            Clear
          </Button>
          <Button block shape="round" type="primary" disabled={!canContinue} onClick={signDocument}>
            Semneaza
          </Button>
        </ButtonsWrapper>
      </SignatureWrapper>
    </DocumentPageWrapper>
  );

  if (signaturePadVisible) {
    return renderSignaturePad();
  }

  const downloadFile = () => {
    const link = window.document.createElement('a');
    link.href = document.url;
    link.download = `${document.name}.pdf`;
    window.document.body.appendChild(link);
    link.click();
    window.document.body.removeChild(link);
  };

  if (location?.state?.onlyViewMode) {
    return (
      <DocumentPageWrapper>
        <PDFWrapper>
          <TransformWrapper
            options={{ centerContent: false, limitToWrapper: true, limitToBounds: false }}
            defaultScale={1}
            defaultPositionX={0}
            defaultPositionY={0}
          >
            {({
              zoomIn, zoomOut, resetTransform, ...rest 
            }) => (
              <TransformComponent>
                <PDFViewer
                  document={{
                    url: document.url || document.uri,
                  }}
                />
              </TransformComponent>
            )}
          </TransformWrapper>
        </PDFWrapper>

        <ButtonWrapper>
          <Button onClick={downloadFile} type="primary" shape="round">
            Descarcă
          </Button>
        </ButtonWrapper>
      </DocumentPageWrapper>
    );
  }

  return (
    <DocumentPageWrapper>
      <PDFWrapper>
        <TransformWrapper
          options={{ centerContent: false, limitToWrapper: true, limitToBounds: false }}
          defaultScale={1}
          defaultPositionX={0}
          defaultPositionY={0}
        >
          {({
            zoomIn, zoomOut, resetTransform, ...rest 
          }) => (
            <TransformComponent>
              <PDFViewer
                document={{
                  url: document.url,
                }}
              />
            </TransformComponent>
          )}
        </TransformWrapper>
      </PDFWrapper>

      <ButtonWrapper>
        {document.signature ? (
          <Button onClick={downloadFile} type="primary" shape="round">
            Descarcă
          </Button>
        ) : (
          <Button onClick={() => setSignaturePadVisible(true)} type="primary" shape="round">
            Semnează
          </Button>
        )}
      </ButtonWrapper>
    </DocumentPageWrapper>
  );
}
