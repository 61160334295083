import styled from "styled-components";
export const StepInsuranceWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: #f2f2f2;
`;

export const StepInsuranceContent = styled.div`
  padding: 32px 16px;
`;
export const SectionWrapper = styled.div`
  margin-bottom: 22px;
`;
