import React, { useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { Checkbox, Form, Input } from 'antd';
import { AppContext } from '../../context/app.context';

import { RegisterPageWrapper } from './register.style';
import { Logo, Button } from '../../components';
import { PageNavigationWrapper } from '../login/login.style';
import { getBrowser } from '../../utils';
import { POLICY_VERSION, TERMS_VERSION } from '../../constants';

export default function RegisterPage() {
  const {
    user, isLoading, handleRegister, userLocation 
  } = useContext(AppContext);
  const history = useHistory();

  const onFinish = (formData) => {
    handleRegister({ ...formData, userInfo: { browser: getBrowser(), userLocation }, docVersions: { terms: TERMS_VERSION, policy: POLICY_VERSION } });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  if (user) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <RegisterPageWrapper>
      <Logo />

      <PageNavigationWrapper>
        <button onClick={() => history.push('/login')}>Intră în cont</button>

        <button
          style={{
            backgroundColor: '#10e4e4',
          }}
          onClick={() => history.push('/register')}
        >
          Creează cont
        </button>
      </PageNavigationWrapper>

      <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Adaugă un nume și un prenume!',
            },
          ]}
        >
          <Input placeholder="Nume si prenume" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Email-ul nu e valid',
            },
            {
              required: true,
              message: 'Adaugă un email!',
            },
          ]}
        >
          <Input placeholder="Email" />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Adauga o parolă',
            },
          ]}
        >
          <Input.Password placeholder="*******" />
        </Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject('Accepta termenii si conditiile')),
            },
          ]}
        >
          <Checkbox>
            Prin apasarea butonului “inregistrare” declar ca am citit si sunt de acord cu
            {' '}
            <a target="_blank" href="/termeni-si-conditii">
              Termenii si Conditiile
            </a>
            {' '}
            si
            {' '}
            <a target="_blank" href="/politica-de-confidentialitate">
              Politica de prelucrarea
            </a>
            {' '}
            a datelor CarFix.
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button className="primary" title="Creează cont" type="primary" loading={isLoading} disabled={isLoading} htmlType="submit" />
        </Form.Item>

        <Button className="info" title="Am uitat parola" onClick={() => history.push('/forgot-password')} />
      </Form>
    </RegisterPageWrapper>
  );
}
