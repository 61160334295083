import styled from 'styled-components';

export const CompletedIRPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  padding-top:32px;
  padding-bottom:15px;
  display:flex;
  justify-content:space-between;
  flex-direction:column;

`;

export const LogoWrapper = styled.div`
  width:100%;
  display:flex;
  justify-content:center;
  img{
    width:70%;
  }
`;

export const IllustrationWrapper = styled.div`
  width:40%;
  heigth:30%;
  margin-left:30%;
  `;

export const TaskInfo = styled.div`
  flex-direction:column;
`;

export const TitleWrapper = styled.div`
    font-size: 18px;
    color: black;
    line-height: 28px;
    font-family: CodecPro-Bold;
    text-align: center;
    margin-top:50px;
`;

export const ContactWrapper = styled.div`
  flex-direction:column;
`;

export const TextWrapper = styled.div`
    font-size: 16px;
    color: black;
    font-family: CodecPro-News;
    text-align: center;
    a{
      color:#37AFAD;
    }
`;
