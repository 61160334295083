/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from "react";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
import daunanouaiconpng from "../../assets/gif/daunanoua-icon.png";

import { CarListItem, HeaderSteps } from "../../components";
import { AppContext } from "../../context/app.context";

import { GaragePageWrapper, SearchContent, CarContent } from "./garage.styled";
export default function GaragePage() {
  const history = useHistory();
  const { user, getClientCars, cars, setSelectedCarFromGarage } = useContext(AppContext);
  const [carsLocal, setCarsLocal] = useState([]);
  const [notFound, setNotFound] = useState(1);
  const [userSearchInput, setUserSearchInput] = useState("");
  const [carsNumber, setCarsNumber] = useState(0);

  useEffect(() => {
    getClientCars(user.user.id);
  }, []);

  useEffect(() => {
    if (cars) {
      setCarsNumber(cars.length);
      setCarsLocal(cars);
    }
  }, [cars]);

  useEffect(() => {
    if (userSearchInput) {
      renderTasks();
    } else {
      setCarsLocal(cars);
    }
  }, [userSearchInput]);

  const renderTasks = () => {
    const futureCarsLocal = cars.filter(
      (car) =>
        car.vin.toLowerCase().includes(userSearchInput.toLowerCase()) ||
        car.model.toLowerCase().includes(userSearchInput.toLowerCase()) ||
        car.make.toLowerCase().includes(userSearchInput.toLowerCase()) ||
        car.registration_number.toLowerCase().includes(userSearchInput.toLowerCase())
    );
    setCarsLocal(futureCarsLocal);
    if (!futureCarsLocal.length && userSearchInput) {
      setNotFound(0);
    } else {
      setNotFound(1);
    }
  };

  return (
    <GaragePageWrapper className="GaragePageWrapper">
      <HeaderSteps
        title="Garajul tau"
        rightChildren={<img onClick={() => history.push("/add-car")} src={daunanouaiconpng} alt="plus" />}
        subtitle={`Ai ${carsNumber} mașini adăugate`}
      />

      <SearchContent className="SearchContent">
        <Input.Search
          placeholder="Caută..."
          value={userSearchInput}
          onChange={(e) => setUserSearchInput(e.currentTarget.value)}
          enterButton
        />
      </SearchContent>

      <CarContent className="CarContent">
        {carsLocal &&
          carsLocal.map((car) => (
            <CarListItem
              onClick={() => {
                setSelectedCarFromGarage(car);
                history.push({
                  pathname: "/view-car",
                  search: `?id=${car.id}`,
                });
              }}
              key={car.id}
              car={car}
            />
          ))}
        <div>{notFound === 0 && <div>Nu s-a găsit nimic</div>}</div>
      </CarContent>
    </GaragePageWrapper>
  );
}
