import styled from "styled-components";

export const AddCarPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;

  background: #f2f2f2;
`;
export const AddCarPageContent = styled.div`
  padding: 16px;
`;
export const ModalContentWrapper = styled.div`
  padding: 16px;

  .founded-car {
    padding: 16px;
    background: #f2f2f2;
    border: 1px solid #eee;
    margin-bottom: 32px;
    display: flex;
    flex-direction: column;

    p {
      line-height: 16px;
      font-size: 16px;
      font-width: 400;
      margin-bottom: 0;
    }
    h3 {
      line-height: 24px;
      font-size: 24px;
      font-width: 600;
      margin-bottom: 0;
    }
  }
`;

export const ImageWrapper = styled.div`
  margin-bottom: 64px;
  display: flex;
  justify-content: center;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 20px;

  .ant-select {
    width: 100%;
  }

  h3 {
    color: #181726;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
`;
