/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { Button, Spin } from "antd";
import { useLocation, useHistory } from "react-router-dom";
import { EditOutlined } from "@ant-design/icons";
import { HeaderSteps, ListItem, Button as CarButton } from "../../components";

import { ImageWrapper, CarPageWrapper, TableWrapper, TableRow, ButtonsWrapper, ViewCarPageContent } from "./view-car.style";
import { AppContext } from "../../context/app.context";

export default function ViewCarPage() {
  const history = useHistory();
  const locationUrl = useLocation();
  const { user, getClientCars, cars } = useContext(AppContext);
  const [car, setCar] = useState(null);
  const [sectionType, setSectionType] = useState("info"); // info / odcumente / utile

  useEffect(() => {
    getClientCars(user.user.id);
  }, []);

  useEffect(() => {
    if (cars) {
      const viewCar = cars.filter((car) => car.id == locationUrl.search.substring(4));
      setCar(viewCar[0]);
    }
  }, [cars]);

  if (!car) {
    return (
      <div
        style={{
          height: "calc(100% - 64px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  const { id, make, model, registration_number, type, vin, fuel_type, model_year } = car;

  const renderInfo = () => (
    <>
      <TableWrapper className="TableWrapper">
        <TableRow className="TableRow">
          <label>Serie sasiu (VIN):</label>

          <span>{vin}</span>
        </TableRow>

        <TableRow className="TableRow">
          <label>Număr mașină:</label>

          <span>{registration_number}</span>
        </TableRow>

        <TableRow className="TableRow">
          <label>Marca mașină:</label>

          <span>{make}</span>
        </TableRow>

        <TableRow className="TableRow">
          <label>Model mașină:</label>

          <span>{model}</span>
        </TableRow>

        <TableRow className="TableRow">
          <label>An mașină:</label>

          <span>{model_year}</span>
        </TableRow>

        <TableRow className="TableRow">
          <label>Caroserie:</label>

          <span>{type}</span>
        </TableRow>

        <TableRow className="TableRow">
          <label>Combustibil:</label>

          <span>{fuel_type}</span>
        </TableRow>

        <TableRow className="TableRow">
          <label>ID mașină:</label>

          <span>{id}</span>
        </TableRow>
      </TableWrapper>
    </>
  );

  const renderDocumente = () => (
    <>
      <ListItem title="Talon" action={() => history.push("/my-documents/talon")} />
      <ListItem title="Rca" action={() => history.push("/my-documents/rca")} />
      <ListItem title="Casco" action={() => history.push("/my-documents/casco")} />
      <ListItem title="CIV" action={() => history.push("/my-documents/civ")} />
    </>
  );

  const renderUtile = () => (
    <>
      <ListItem title="ITP" action={() => history.push("/my-documents/itp")} />
      <ListItem title="Rovinieta" action={() => history.push("/my-documents/rovinieta")} />
      <ListItem title="Extinctor" action={() => history.push("/my-documents/extinctor")} />
      <ListItem title="Trusa medicală" action={() => history.push("/my-documents/trusa")} />
      <ListItem title="Revizii" action={() => history.push("/my-documents/revizii")} />
    </>
  );

  return (
    <CarPageWrapper>
      <HeaderSteps title="Detalii mașină" subtitle={`${make} ${model}`} />

      <ImageWrapper className="ImageWrapper">
        {car.avatar && <img style={{ width: "100%" }} alt="car avatar" src={car.avatar} />}
        <div className="car-info">
          <span>{make}</span>
          <Button
            className="edit-button"
            onClick={() => {
              history.push({ pathname: "/edit-car", search: `?id=${id}` });
            }}
            type="primary"
            shape="round"
          >
            <EditOutlined />
          </Button>
        </div>
      </ImageWrapper>

      <ButtonsWrapper
        className="ButtonsWrapper"
        style={{
          padding: 20,
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <CarButton className={sectionType === "info" && "primary"} onClick={() => setSectionType("info")} title="Info" />
        <CarButton className={sectionType === "documente" && "primary"} onClick={() => setSectionType("documente")} title="Documente" />
        <CarButton className={sectionType === "utile" && "primary"} onClick={() => setSectionType("utile")} title="Utile" />
      </ButtonsWrapper>

      <ViewCarPageContent>
        {sectionType === "info" && renderInfo()}
        {sectionType === "documente" && renderDocumente()}
        {sectionType === "utile" && renderUtile()}
      </ViewCarPageContent>
    </CarPageWrapper>
  );
}
