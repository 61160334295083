import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FooterWrapper, FooterLinkButton, FooterContentWrapper } from './footer.styled';

import dashiconpng from '../../assets/gif/dash-icon.png';
import generaliconpng from '../../assets/gif/general-icon.png';
import setticonpng from '../../assets/gif/sett-icon.png';
import { AppContext } from '../../context/app.context';

export default function Footer() {
  const location = useLocation();
  const history = useHistory();
  const { preventChangeScreen, setPreventChangeScreen } = useContext(AppContext);

  const onClick = (route) => {
    if (!preventChangeScreen) {
      return history.push(route);
    }
    
    if (window.confirm('Esti sigur ca vrei sa parasesti ecran-ul?')) {
      setPreventChangeScreen(false);
      return history.push(route);
    } 
  };
  return (
    <FooterWrapper>
      <FooterContentWrapper>
        <FooterLinkButton onClick={() => onClick('/dashboard')} className={`${location.pathname === '/dashboard' ? 'active' : 'inactive'}`}>
          <img src={dashiconpng} alt="dash icon" />
        </FooterLinkButton>

        <FooterLinkButton onClick={() => onClick('/general-actions')} className={`${location.pathname === '/general-actions' ? 'active' : 'inactive'}`}>
          <img src={generaliconpng} alt="general icon" />
        </FooterLinkButton>

        <FooterLinkButton onClick={() => onClick('/settings')} className={`${location.pathname === '/settings' ? 'active' : 'inactive'}`}>
          <img src={setticonpng} alt="settings icon" />
        </FooterLinkButton>
      </FooterContentWrapper>
    </FooterWrapper>
  );
}
