import React, { useState, useEffect } from "react";

import { LoaderWrapper } from "./loader.style";
import { Spin } from "antd";
export default function Loader({ textArray }) {
  const [currentText, setCurrentText] = useState(textArray ? textArray[0] : null);
  const [currentIndex, setCurrentIndex] = useState(1);

  useEffect(() => {
    if (textArray) {
      let id = setInterval(() => {
        if (textArray.length - 1 === currentIndex) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex(currentIndex + 1);
        }

        setCurrentText(textArray[currentIndex]);
      }, 1700);
      return () => clearInterval(id);
    }
  });

  return (
    <LoaderWrapper>
      <Spin size="large" />
      {textArray && <p style={{ marginTop: 16, color: "#989898", fontSize: 16, fontStyle: "italic" }}>{currentText}</p>}
    </LoaderWrapper>
  );
}
