import React from 'react';
import { ReactComponent as LogoSvg } from '../../assets/images/new-logo.svg';

import { LogoWrapper } from './splash.styled';

export default function Splash() {
  return (
    <LogoWrapper className="LogoWrapper">
      <LogoSvg />
    </LogoWrapper>
  );
}
