import React, { useState } from 'react';
import { Input } from 'antd';

import { InputWrapper } from './input.styled';

export default function InputWeb({
  autoCompleteType,
  multiline,
  keyboardType,
  style,
  label,
  text,
  editable,
  defaultValue,
  onChangeText,
  placeholder,
  secureTextEntry,
  autoCapitalize,
  normalize
}) {
  const [borderColor, setBorderColor] = useState('#707070');
  return (
    <InputWrapper className="InputWrapper" style={style}>
      {label && <label>{label}</label>}

      <Input
        normalize={normalize}
        type={secureTextEntry ? 'password' : 'text'}
        style={{
          borderColor,
        }}
        inputMode={keyboardType}
        autoCapitalize={autoCapitalize}
        allowClear
        defaultValue={defaultValue || null}
        onFocus={() => setBorderColor('#10e4e4')}
        onBlur={() => setBorderColor('#707070')}
        placeholder={placeholder}
        onChange={(e) => onChangeText(e.currentTarget.value)}
        value={text}
      />
    </InputWrapper>
  );
}
