import React, { useContext, useEffect, useState } from 'react';
import { InputWeb } from '../../components';
import { POLICY_VERSION, TERMS_VERSION } from '../../constants';
import { AppContext } from '../../context/app.context';
import { ComponentsContext } from '../../context/components.context';
import { getBrowser } from '../../utils';
import { AskForRegisterContent, AskForRegisterWrapper } from './askForRegister.style';

export default function AskForRegisterModal() {
  const { user, updateProfile, userLocation } = useContext(AppContext);
  const { setComponentLevel0 } = useContext(ComponentsContext);

  const [mode, setMode] = useState('ask');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [canContinue, setCanContinue] = useState(false);
  const [termsCheckbox, setTermsCheckbox] = useState(false);

  useEffect(() => {
    if (password && confirmPassword && password === confirmPassword && termsCheckbox) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [password, confirmPassword, termsCheckbox]);

  const renderAsk = () => (
    <>
      <h2>
        Salut
        {' '}
        {user?.user?.full_name}
      </h2>
      <h3>Vrei să-ți faci cont?</h3>

      <div className="buttons-wrapper">
        <button type="button" onClick={() => setComponentLevel0(null)}>
          Nu
        </button>
        <button type="button" className="primary" onClick={() => setMode('register')}>
          Da
        </button>
      </div>
    </>
  );

  const renderRegister = () => (
    <>
      <h2>Introdu parola pentru email-ul</h2>
      <h3>{user?.user?.email}</h3>

      <InputWeb
        secureTextEntry
        placeholder="*********"
        label="Parolă"
        text={password}
        onChangeText={(e) => {
          setPassword(e);
        }}
      />

      <InputWeb
        secureTextEntry
        placeholder="*********"
        label="Confirmă parola"
        text={confirmPassword}
        onChangeText={(e) => {
          setConfirmPassword(e);
        }}
      />

      <div className="checkbox-wrapper">
        <input
          type="checkbox"
          checked={termsCheckbox}
          name="Terms"
          value="Terms"
          onChange={(e) => setTermsCheckbox(e.currentTarget.checked)}
        />
        Prin apasarea butonului “inregistrare” declar ca am citit si sunt de acord cu
        {' '}
        <a target="_blank" href="/termeni-si-conditii">
          Termenii si Conditiile
        </a>
        {' '}
        si
        {' '}
        <a target="_blank" href="/politica-de-confidentialitate">
          Politica de prelucrarea
        </a>
        {' '}
        a datelor CarFix.
      </div>

      <div className="buttons-wrapper">
        <button type="button" onClick={() => setMode('ask')}>
          Înapoi
        </button>
        <button
          disabled={!canContinue}
          type="button"
          className="primary"
          onClick={async () => {
            const res = await updateProfile({
              email: user?.user?.email,
              password,
              repeatPassword: confirmPassword,
              userInfo: { browser: getBrowser(), userLocation },
              docVersions: { terms: TERMS_VERSION, policy: POLICY_VERSION } 
            });

            if (res.status === 200) {
              setComponentLevel0(null);
            }
          }}
        >
          Fă-ti cont
        </button>
      </div>
    </>
  );
  return (
    <AskForRegisterWrapper className="AskForRegisterWrapper">
      <AskForRegisterContent className="AskForRegisterContent">
        {mode === 'ask' && renderAsk()}
        {mode === 'register' && renderRegister()}
      </AskForRegisterContent>
    </AskForRegisterWrapper>
  );
}
