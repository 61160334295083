import styled from "styled-components";

import { Button as AntButton } from "antd";

export const Button = styled(AntButton)`
  width: 100%;
  border-radius: 52px;
  margin-top: 32px;
  background: #10e4e4;
  color: #181726;
  font-size: 14px;
  line-height: 26px;
  border: 0;
  padding: 13px 0;
  display: inline-block;
  height: auto;
  font-weight: 500;
  text-transform: uppercase;
`;
export const PickRoleWrapper = styled.div`
  padding: 92px 32px 32px 32px;
  min-height: 100vh;

  .LogoWrapper {
    width: 50%;
    margin: 0 auto 50px auto;
  }
`;
export const RoleWrapper = styled.div`
  padding: 32px;
  transition: 0.2s;
  background: rgb(249, 251, 252);
  margin-top: 32px;
  margin-bottom: 10px;
  border-radius: 16px;
  text-align: center;
  padding: 50px 30px;
  color: #464646;
  font-size: 18px;
  line-heigth: 26px;
  font-weight: bold;

  position: relative;

  span {
    font-size: 16px;
    font-weight: normal;
    display: block;
  }

  &.active {
    background: #181726;
    color: white;
    box-shadow: 0px 0px 70px -40px #000;
  }
`;

export const WorkEnvWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    background: transparent;
    padding: 8px 32px;
    border-radius: 33px;
    border: none;
    outline: none;
  }
`;

export const RolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.button`
  width: 100%;
  border-radius: 52px;
  margin-top: 32px;
  background: #10e4e4;
  color: #181726;
  font-size: 14px;
  line-height: 26px;
  border: 0;
  padding: 13px 0;
  display: inline-block;
  height: auto;
  font-weight: 500;
  text-transform: uppercase;
`;

export const IconWrapper = styled.span`
  position: absolute;
  top: 8px;
  right: 8px;

  svg {
    background: #10e4e4;
    color: #000;
    font-size: 34px;
    border-radius: 100%;
    font-weight: 300;
    overflow: hidden;
  }
`;
