import React, { useContext, useEffect, useState } from "react";
import { HeaderSteps, SelectRadio, Button, Loader, DatePickerWeb } from "../../../../components";
import { TrusaSubPageWrapper, TrusaSubPageContent } from "./trusa.style";
import { AppContext } from "../../../../context/app.context";
import { useHistory } from "react-router-dom";

export default function TrusaSubpage() {
  const { user, getUserDocuments, saveUserDocuments, selectedCarFromGarage } = useContext(AppContext);

  const [pageLoaded, setPageLoaded] = useState(false);

  const history = useHistory();
  const [endDate, setEndDate] = useState(null);

  const [alert, setAlert] = useState(false);

  const fetchDocument = async () => {
    const res = await getUserDocuments("trusa_med", selectedCarFromGarage.id);

    if (res.error) {
      return;
    }

    setAlert(res.data.alert);
    setEndDate(res.data.end_date);

    setPageLoaded(true);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: "trusa_med",
      id_user: user.user.id,
      end_date: endDate,
      alert: alert,
      car_id: selectedCarFromGarage.id,
    });

    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }

  return (
    <TrusaSubPageWrapper className="TrusaSubPageWrapper">
      <HeaderSteps title="Trusa" subtitle={`Mașină ${selectedCarFromGarage.registration_number}`} />

      <TrusaSubPageContent className="TrusaSubPageContent">
        <DatePickerWeb value={endDate} setValue={setEndDate} label="Dată expirare:" />

        <SelectRadio
          label="Alertă"
          onTextChange={(e) => {}}
          keyValue="value"
          keyName="label"
          items={[
            { label: "Da", value: true },
            { label: "Nu", value: false },
          ]}
          defaultValue={alert}
          placeholder="Vrei să primești o alertă când expiră?"
          onItemSelect={(item) => {
            setAlert(item.value);
          }}
        />

        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </TrusaSubPageContent>
    </TrusaSubPageWrapper>
  );
}
