import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  HeaderSteps, Button, Uploader, Loader 
} from '../../../../components';
import { AppContext } from '../../../../context/app.context';
import { TalonSubPageWrapper, TalonSubPageContent } from './talon.style';

export default function TalonSubpage() {
  const {
    user, getUserDocuments, saveUserDocuments, selectedCarFromGarage 
  } = useContext(AppContext);

  const [document, setDocument] = useState(null);

  const [pageLoaded, setPageLoaded] = useState(false);

  const history = useHistory();
  const fetchDocument = async () => {
    const res = await getUserDocuments('talon', selectedCarFromGarage.id);

    if (res.error) {
      return;
    }

    setDocument(res.data.document);

    setPageLoaded(true);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: 'talon',
      id_user: user.user.id,
      document,
      car_id: selectedCarFromGarage.id,
    });

    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }
  return (
    <TalonSubPageWrapper className="TalonSubPageWrapper">
      <HeaderSteps title="Talon" subtitle={`Mașină ${selectedCarFromGarage.registration_number}`} />

      <TalonSubPageContent className="TalonSubPageContent">
        <Uploader label="Document Talon" document={document} setDocument={setDocument} />

        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </TalonSubPageContent>
    </TalonSubPageWrapper>
  );
}
