import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Spin, Button } from 'antd';
import { AppContext } from '../../../../context/app.context';
import { CarListItem, HeaderSteps } from '../../../../components';

import daunanouaiconpng from '../../../../assets/gif/daunanoua-icon.png';

import {
  PickCarPageWrapper, ButtonWrapper, LoadingWrapper, ButtonWrapperIR, SpinWrapper, FormContent 
} from './step-pick-car.styled';
import { storageGet } from '../../../../storage/localStorage';

const searchStep = (array, step) => {
  try {
    const x = array.find((x) => x.step === step);
    return x;
  } catch (e) {}
};

export default function StepPickCar() {
  const {
    user, getClientCars, cars, futureTask, isLoading, setFutureTask, setCurrentMap2DObject, initialFutureTask
  } = useContext(AppContext);
  const customTemplate = storageGet('customTemplate');

  const [step, setStep] = useState(storageGet('currentStep')); // route.params.step
  const [template, setTemplate] = useState(searchStep(customTemplate?.form, step));

  const selectCar = (car) => {
    setFutureTask({ ...initialFutureTask, car });
  };

  const history = useHistory();
  // Local state
  useEffect(() => {
    getClientCars(futureTask.client.id || user.user.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderLoading = () => (
    <LoadingWrapper>
      <Spin size="large" />
    </LoadingWrapper>
  );

  const renderContent = () => (
    <FormContent className="FormContent">
      {cars
          && cars.map((car) => (
            <CarListItem
              onClick={() => {
                if (futureTask.type === 'IR') {
                  return null;
                }

                selectCar(car);
                setCurrentMap2DObject(null);
              }}
              key={car.id}
              car={car}
            />
          ))}

      {!cars
          || (cars.length === 0 && (
            <div className="no-car-msg">
              Te rugam adauga masina pentru care vrei sa faci 
              {' '}
              {futureTask.type}
              {' '}
              <button className="add-car-btn" onClick={() => history.push('/add-car')}>
                Adaugă mașină
              </button>
            </div>
          ))}
    </FormContent>
  );

  const renderFNOL = () => (
    <PickCarPageWrapper className={`PickCarPageWrapper ${futureTask.car.id && 'paddingButtom'}`}>
      <HeaderSteps
        title="Mașină"
        subtitle="Alege o mașină"
        rightChildren={<img onClick={() => history.push('/add-car')} src={daunanouaiconpng} alt="plus" />}
      />

      {isLoading ? renderLoading() : renderContent()}
      {!isLoading && futureTask.car.id && (
        <ButtonWrapper className="ButtonWrapper fadeInUp animated faster">
          <Button
            onClick={() => {
              history.push({ pathname: '/step-insurance' });
            }}
            type="primary"
            shape="round"
          >
            Urmatorul pas
          </Button>
        </ButtonWrapper>
      )}
    </PickCarPageWrapper>
  );

  const renderIR = () => (
    <PickCarPageWrapper className={`PickCarPageWrapper ${futureTask.car.id && 'paddingButtom'}`}>
      <HeaderSteps
        step={step}
        setStep={setStep}
        setTemplate={setTemplate}
        title={template && template.title}
        subtitle="Alege o masina"
        procent={(step * 100) / customTemplate.form.length}
      />
      {isLoading ? renderLoading() : renderContent()}
      {!isLoading && futureTask.car.id && (
        <ButtonWrapperIR className="ButtonWrapper fadeInUp animated faster">
          <Button
            className="bounceInLeft animated"
            onClick={() => {
              setCurrentMap2DObject(null);
              history.push(`/step-custom/${template.nextStepDamage}`);
            }}
            shape="round"
          >
            Masina are daune
          </Button>
          <Button
            className="bounceInRight animated"
            onClick={() => {
              setCurrentMap2DObject(null);
              history.push(`/step-custom/${template.nextStepNoDamge}`);
            }}
            type="primary"
            shape="round"
          >
            Masina nu are daune
          </Button>
        </ButtonWrapperIR>
      )}
    </PickCarPageWrapper>
  );

  if (!futureTask.type) {
    return (
      <SpinWrapper>
        <Spin size="small" />
      </SpinWrapper>
    );
  }

  return futureTask.type === 'FNOL' ? renderFNOL() : renderIR();
}
