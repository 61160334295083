import styled from "styled-components";

export const DocumentListItemWrapper = styled.div`
  padding: 16px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 4px;
  border-radius: 8px;
  background: #f9f9f9;
  margin-top: 4px;

  .document-name {
    width: auto;
    font-weight: 500;
    text-transform: capitalize;
    color: #101010;
    font-size: 12px;
  }

  .signature {
    width: auto;
    font-weight: 500;
    text-transform: capitalize;
    color: #101010;
    font-size: 12px;
    text-align: right;
  }
`;
export const UnsignedDocumentWrapper = styled.div`
  .signature {
    background: #ec3f3f;
    color: #fff;
    padding: 2px 5px;
    border-radius: 33px;
    margin-right: -5px;
  }
`;
export const SignedDocumentWrapper = styled.div`
  .ant-badge {
    width: 100%;
  }
`;
