import styled from "styled-components";

export const RegisterPageWrapper = styled.div`
  padding: 92px 32px 32px 32px;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .LogoWrapper {
    width: 50%;
    margin: 0 auto 50px auto;
  }

  input,
  .ant-input-password {
    border: none;
    border-bottom: 2px solid #ddd;
    outline: none;
    box-shadow: none;

    &:hover {
      border-color: #10e4e4;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .ButtonWrapper.primary {
    width: 100%;
    border-radius: 52px;
    margin-top: 32px;
    background: #10e4e4;
    color: #181726;
    font-size: 14px;
    line-height: 26px;
    border: 0;
    padding: 13px 0;
    display: inline-block;
    height: auto;
    font-weight: 500;
    text-transform: uppercase;
  }

  .info {
    color: black;
  }
  #form-login {
    // height: 100%;
    // justify-content: space-between;
    // display: flex;
    // flex-direction: column;
  }
`;
