/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import {
  message, Spin, Select, Button 
} from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../context/app.context';
import { FancyHeader } from '../../../../components';
import { isEmpty } from '../../../../utils';

import {
  StepPickClientWrapper, SpinWrapper, FormWrapper, FormTopWrapper, ButtonWrapper 
} from './step-pick-client.styled';
import { storageSave } from '../../../../storage/localStorage';

const demoIR = {
  template: 'Template super',
  user_geolocation: {
    latitude: null,
    longitude: null,
  },
  form: [
    {
      title: 'Pasul 2',
      subtitle: 'Alege masina',
      step: 2,
      nextStep: 3,
      nextStepDamage: 3,
      nextStepNoDamge: 4,
      type: 'hardcoded',
      page: 'pick-car',
    },
    {
      title: 'Pasul 3',
      subtitle: 'Alege masina',
      step: 3,
      nextStep: 4,
      type: 'hardcoded',
      page: '2D-map',
    },
    {
      title: '4. Poze generale',
      subtitle: '',
      step: 4,
      nextStep: 5,
      type: 'custom',
      inputs: [
        {
          type: 'photo',
          attribute: 'car_general/fata',
          category: 'Poze generale',
          general_label: 'Poze fata',
          photos: [
            {
              label: 'Poze fata',
              coords: {
                longitude: null,
                latitude: null,
              },
              value: null,
            },
          ],
        },
        {
          type: 'photo',
          attribute: 'car_general/spate',
          category: 'Poze generale',
          general_label: 'Poze spate',
          photos: [
            {
              label: 'Poze spate',
              coords: {
                longitude: null,
                latitude: null,
              },
              value: null,
            },
          ],
        },
        {
          type: 'photo',
          attribute: 'car_general/stanga',
          category: 'Poze generale',
          general_label: 'Poze stanga',
          photos: [
            {
              label: 'Poze stanga',
              coords: {
                longitude: null,
                latitude: null,
              },
              value: null,
            },
          ],
        },
        {
          type: 'photo',
          attribute: 'car_general/dreapta',
          category: 'Poze generale',
          general_label: 'Poze dreapta',
          photos: [
            {
              label: 'Poze dreapta',
              coords: {
                longitude: null,
                latitude: null,
              },
              value: null,
            },
          ],
        },
        {
          type: 'photo',
          attribute: 'car_general/bord',
          category: 'Poze generale',
          general_label: 'Poze bord',
          photos: [
            {
              label: 'Bord',
              value: null,
            },
          ],
        },
        {
          type: 'photo',
          attribute: 'car_general/vin',
          category: 'Poze generale',
          general_label: 'Poza vin',
          photos: [
            {
              label: 'Poza vin',
              value: null,
            },
          ],
        },
        {
          type: 'photo',
          attribute: 'car_general/general_bord',
          category: 'Poze generale',
          general_label: 'Imagine Generala Bord',
          photos: [
            {
              label: 'Imagine Generala Bord',
              value: null,
            },
          ],
        },
      ],
    },
    {
      title: 'Pasul final',
      subtitle: 'Sumar',
      step: 5,
      nextStep: null,
      type: 'hardcoded',
      page: 'summary',
    },
  ],
};

export default function StepPickClient() {
  // eslint-disable-next-line no-unused-vars
  const [irTemplate, setIrTemplate] = useState(demoIR);
  const {
    user, setIsLoading, isLoading, futureTask, setFutureTask, API_URL, setCustomStepTemplate 
  } = useContext(AppContext);
  const [inspectorClients, setInspectorClients] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    axios({
      method: 'get',
      url: `${API_URL}/inspection/clients`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setInspectorClients(response.data.message.clients);
        setIsLoading(false);
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <SpinWrapper>
        <Spin size="large" />
      </SpinWrapper>
    );
  }

  const handleChange = (e) => {
    const client = inspectorClients.find((client) => client.id === e);
    setFutureTask({ ...futureTask, client });
  };

  return (
    <StepPickClientWrapper>
      <FancyHeader title="Alege un client" subtitle="Alege masina clientului" />

      <FormWrapper>
        <FormTopWrapper>Alege un client</FormTopWrapper>
        <Select
          size="large"
          placeholder="Selecteaza un client"
          allowClear
          showSearch
          onChange={handleChange}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {inspectorClients.map((client) => (
            <Select.Option key={client.id} value={client.id}>
              {client.full_name}
            </Select.Option>
          ))}
        </Select>

        <ButtonWrapper className="ButtonWrapper">
          <Button
            disabled={isEmpty(futureTask.client)}
            onClick={() => {
              setCustomStepTemplate(irTemplate);
              storageSave('customTemplate', irTemplate);
              history.push({ pathname: '/step-custom/2' });
            }}
            type="primary"
            shape="round"
          >
            Urmatorul pas
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </StepPickClientWrapper>
  );
}
