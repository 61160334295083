import React, { useContext } from "react";
import { AppContext } from "../../context/app.context";
import { useHistory, Redirect } from "react-router-dom";
import { Form, Input } from "antd";

import { LoginPageWrapper, PageNavigationWrapper } from "./login.style";
import { Logo, Button } from "../../components";

export default function LoginPage() {
  const { user, authenticate, isLoading, loginRole, handleAnonymousLogin } = useContext(AppContext);
  const history = useHistory();

  const onFinish = (formData) => {
    authenticate(formData);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (user) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <LoginPageWrapper>
      <Logo />

      <PageNavigationWrapper>
        {loginRole !== "constatator" && (
          <button
            style={{
              backgroundColor: "#10e4e4",
            }}
            onClick={() => history.push("/login")}
          >
            Intră în cont
          </button>
        )}

        {loginRole !== "constatator" && <button onClick={() => history.push("/register")}>Creează cont</button>}
      </PageNavigationWrapper>

      <Form
        id="form-login"
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <div>
          <Form.Item
            name="username"
            rules={[
              { required: true, message: "Adauga un email!" },
              { type: "email", message: "Adauga un email valid!" },
            ]}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: "Adauga o parola!" }]}>
            <Input.Password placeholder="Password" />
          </Form.Item>
        </div>
        <div>
          <Form.Item>
            <Button isLoading={isLoading} className="primary" title="Intra" loading={isLoading} disabled={isLoading} htmlType="submit" />
          </Form.Item>
        </div>
      </Form>

      <div>    
        {loginRole !== "constatator" && (
          <Button
            onClick={() => {
              handleAnonymousLogin();
            }}
            className="guest"
            title="Continua fara cont"
            loading={isLoading}
            disabled={isLoading}
          />
        )}
      </div>

      <Button className="info" title="Am uitat parola" onClick={() => history.push("/forgot-password")} />
    </LoginPageWrapper>
  );
}
