import styled from "styled-components";

export const SelectWrapper = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 22px 42px 32px 42px;
  width: 100%;
  label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 16px;
  }

  div.ant-select-selector {
    height: 37px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-radius: 33px !important;
    border: 1px solid #707070 !important;
  }

  .ant-select {
    width: 100%;
  }
`;
