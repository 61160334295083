import React from 'react';
import { useHistory } from 'react-router-dom';
import { ListItem, HeaderSteps } from '../../../../components';
import { LegalDocumentsPageWrapper, LegalDocumentsPageContent } from "./legal-documents.style";

export default function LegalDocumentsPage() {
    const history = useHistory();

    return (
        <LegalDocumentsPageWrapper className="LegalDocumentsPageWrapper">
            <HeaderSteps title='Legal'/>
            <LegalDocumentsPageContent className="LegalDocumentsPageContent">
                <ListItem title='Termeni si conditii' action={() => history.push('/termeni-si-conditii')} />
                <ListItem title='Politica de confidentialitate' action={() => history.push('/politica-de-confidentialitate')} />
            </LegalDocumentsPageContent>
        </LegalDocumentsPageWrapper>
    );
}