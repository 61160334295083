import styled from "styled-components";

export const UploaderWrapper = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 22px 42px 32px 42px;
  position: relative;
  width: 100%;
  label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 16px;
  }
`;
