import { DeleteOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { AppContext } from "../../context/app.context";
import DocumentListItem from "../document-list-item/document-list-item.component";
import ImagePreviewComponent from "../image-preview/image-preview.component";
import { UploaderWrapper } from "./uploader.style";

export default function Uploader({ style, enableUpload = false, label, document, setDocument }) {
  const { uploadFile } = useContext(AppContext);

  const _setFileInDocument = async (event) => {
    // Get image from upload event
    const eventFile = event.target.files[0];
    let uri = URL.createObjectURL(eventFile);

    // Create new photo file
    let futureFile = {
      name: eventFile.name,
      uri: uri,
      type: eventFile.type,
    };

    if (enableUpload) {
      const pdfTmpLink = await uploadFile({ file: eventFile });
      futureFile.uri = pdfTmpLink.url;
      futureFile.url = pdfTmpLink.url;
    }

    setDocument(futureFile);
  };

  const _deleteDocument = () => {
    setDocument(null);
  };

  if (document?.type === "photo" || document?.type === "image" || document?.type === "image/jpeg" || document?.type === "image/png") {
    return (
      <UploaderWrapper style={style} className="UploaderWrapper">
        {label && <label htmlFor="file-input">{label}</label>}

        <div onClick={_deleteDocument} style={{ position: "absolute", right: 42, top: 16 }}>
          <DeleteOutlined />
        </div>

        <ImagePreviewComponent
          withoutFileName
          title={document.name}
          multipleMode
          disabled={true}
          label={document.name}
          withPreview={false}
          photoURI={document.uri}
        />
      </UploaderWrapper>
    );
  }

  if (document?.type === "doc" || document?.type === "success" || document?.type === "application/pdf") {
    document.url = document.uri;

    return (
      <UploaderWrapper style={style} className="UploaderWrapper">
        {label && <label htmlFor="file-input">{label}</label>}

        <div onClick={_deleteDocument} style={{ position: "absolute", right: 42, top: 16 }}>
          <DeleteOutlined />
        </div>

        <DocumentListItem document={document} onlyViewMode />
      </UploaderWrapper>
    );
  }

  return (
    <UploaderWrapper style={style} className="UploaderWrapper">
      {label && <label htmlFor="file-input">{label}</label>}

      <input id="file-input" onChange={_setFileInDocument} type="file" name="file" accept="image/*, application/pdf" />
    </UploaderWrapper>
  );
}
