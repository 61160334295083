import React, {
  useState, useEffect, useReducer, useRef,
} from 'react';
import { DatePicker, TimePicker } from 'antd';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment';
import {
  ImagePreviewComponent,
  MultipleImagesPreview,
  SelectRadio,
  SelectWeb,
  InputWeb,
  HeaderSteps,
  Button,
  Loader,
  Uploader,
} from '../../../../components';

import {
  CustomAlert, TimePickerWrapper, StepCustomWrapper, FormContent,
} from './step-custom.styled';

import { storageSave, storageGet } from '../../../../storage/localStorage';

const searchStep = (array, step) => {
  try {
    const x = array.find((x) => x.step === step);
    return x;
  } catch (e) {}
};

const getByRef = (array, ref) => {
  let returnedRef = null;
  array.form.map((step) => {
    if (step.inputs) {
      const foundedRef = step.inputs.find((input) => input.ref === ref);
      if (foundedRef) {
        returnedRef = foundedRef;
      }
    }
  });
  return returnedRef;
};

export default function StepCustom() {
  const history = useHistory();
  const [customTemplate, setCustomTemplate] = useState(storageGet('customTemplate'));
  const isAdmin = null; // from params in app

  const pageWrapper = useRef(null);
  const [customAlert, setCustomAlert] = useState(null);

  // If it comes from summary (change button text and login)
  const params = useParams();
  const [step, setStep] = useState(parseInt(params.id)); // route.params.step
  const [template, setTemplate] = useState(searchStep(customTemplate.form, step));

  const [update, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (template && template.type === 'hardcoded') {
      if (template.page === 'pick-car') {
        history.replace({ pathname: '/step-pick-car' });
      }
      if (template.page === '2D-map') {
        history.replace({ pathname: '/step-map-2d' });
      }
      if (template.page === 'summary') {
        history.replace({ pathname: '/step-summary' });
      }
    }
  }, [step]);

  const renderRequiredLabel = (input) => {
    if (!input.isRequired) {
      return '';
    }

    if (input.isRequired && input.isRequired.value === 1) {
      return '* ';
    }
    if (input.isRequired && input.isRequired.value === 2) {
      const conditionInputRef = input.isRequired.condition.ref;

      const conditionItem = getByRef(customTemplate, conditionInputRef);

      if (JSON.stringify(conditionItem.value) === JSON.stringify(input.isRequired.condition.value)) {
        return '* ';
      }
    }
    return '';
  };

  const renderRadio = (input, index) => (
    <SelectRadio
      style={input.style}
      label={`${renderRequiredLabel(input)}${input.label}`}
      items={input.data}
      keyValue={input.keyValue}
      keyName={input.keyName}
      defaultValue={input.value && input.value[input.keyValue]}
      onItemSelect={(item) => {
        const futureTemplate = template;
        futureTemplate.inputs[index].value = item;
        setTemplate({ ...futureTemplate });
        validateFields();
        forceUpdate();
      }}
    />
  );
  const [canContinue, setCanContinue] = useState(false);

  // If is required = 1
  const validate1 = (input) => {
    if (input.file && input.file.uri) {
      return true;
    }

    if (input.photos && input.photos.every((photo) => photo.value !== null)) {
      return true;
    }
    if (input.value) {
      return true;
    }
    return false;
  };

  // If validate = 2 (conditional)
  const validate2 = (input) => {
    const conditionInputRef = input.isRequired.condition.ref;

    const conditionItem = getByRef(customTemplate, conditionInputRef);

    if (JSON.stringify(conditionItem.value) === JSON.stringify(input.isRequired.condition.value)) {
      return validate1(input);
    }

    return true;
  };

  // Handle template change
  useEffect(() => {
    if (template) {
      const cloneCustomTemplate = { ...customTemplate };
      let editedStep = cloneCustomTemplate.form.find((form) => form.step === step);
      editedStep = template;
      setCustomTemplate({ ...cloneCustomTemplate });
      validateFields();
    }
  }, [template]);

  // Save the template after every change
  useEffect(() => {
    // Find next step template and set it
    const index = customTemplate.form.findIndex((x) => x.step === template.step);
    customTemplate.form[index] = template;
    storageSave('customTemplate', customTemplate);
  }, [update]);

  // Handle url change
  const location = useLocation();
  useEffect(() => {
    setStep(parseInt(params.id));
    setTemplate(searchStep(customTemplate.form, parseInt(params.id)));
    storageSave('currentStep', parseInt(params.id));
  }, [location]);

  const validateFields = () => {
    const validRequired = template?.inputs?.every((input, index) => {
      if (!isVisible(input)) {
        return true;
      }
      if (input.isRequired && input.isRequired.value === 1) {
        // Required fields
        return validate1(input);
      } if (input.isRequired && input.isRequired.value === 2) {
        // Conditional fields
        return validate2(input);
      }
      return true;
    });

    // Validate regex
    const validRegex = template?.inputs?.every((input, index) => {
      if (input.validator && input.validator.value === 1) {
        const regexp = new RegExp(input.validator.regex.value.replace(/%5C/gm, '\\'), 'gmi');
        const result = regexp.test(input.value);

        // If empty let continue;
        if (!input.value) {
          return true;
        }
        return result;
      }
      return true;
    });

    setCanContinue(validRequired && validRegex);
  };

  const renderText = (input, index) => {
    let message = null;

    if (input.validator) {
      const regexp = new RegExp(input.validator.regex.value.replace(/%5C/gm, '\\'), 'gmi');

      const result = regexp.test(input.value);
      if (!result && input.value) {
        message = input.validator.regex.message;
      }
    }

    return (
      <>
        <InputWeb
          style={input.style}
          label={`${renderRequiredLabel(input)}${input.label}`}
          text={input.value}
          secureTextEntry={input.secureTextEntry}
          keyboardType={input.keyboardType}
          autoCompleteType={input.autoCompleteType}
          multiline={input.multiline}
          onChangeText={(e) => {
            const futureTemplate = template;
            futureTemplate.inputs[index].value = e;
            setTemplate(futureTemplate);
            validateFields();

            forceUpdate();
          }}
          placeholder={input.placeholder}
        />

        {message && (
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#fff',
              paddingVertical: 16,
              position: 'relative',
              textAlign: 'center',
              bottom: 32,
              width: '100%',
              color: 'red',
              borderBottomLeftRadius: 16,
              borderBottomRightRadius: 16,
              paddingBottom: 6,
            }}
          >
            <p>{message}</p>
          </div>
        )}
      </>
    );
  };

  const renderUploader = (form, index) => (
    <Uploader
      enableUpload
      style={form.style}
      label={form.general_label}
      document={form.file}
      setDocument={(newFile) => {
        const futureTemplate = template;
        futureTemplate.inputs[index].file = newFile;
        setTemplate(futureTemplate);
        validateFields();
        forceUpdate();
      }}
    />
  );

  const renderTextArea = (form, index) => (
    <>
      <InputWeb
        style={form.style}
        label={form.label}
        onChangeText={(e) => {
          const futureTemplate = template;
          futureTemplate[index].value = e;

          setTemplate([...futureTemplate]);
          validateFields();
          forceUpdate();
        }}
        placeholder={form.placeholder}
      />
    </>
  );

  const renderDate = (input, index) => (
    <>
      <TimePickerWrapper style={input.style} className="TimePickerWrapper">
        <label>{input.label}</label>
        <DatePicker
          format="DD-MM-YYYY"
          disabledDate={(current) => current && current > moment().endOf('day')}
          autoFocus
          inputReadOnly
          onChange={(date, dateString) => {
            const futureTemplate = template;
            futureTemplate.inputs[index].value = dateString;
            setTemplate(futureTemplate);
          }}
            // defaultValue={input.value}
          defaultValue={input.value ? moment(input.value) : ''}
          style={{ width: '100%' }}
        />
      </TimePickerWrapper>
    </>
  );

  const renderTime = (input, index) => (
    <>
      <TimePickerWrapper className="TimePickerWrapper">
        <label>{input.label}</label>
        <TimePicker
          inputReadOnly
          onChange={(time, timeString) => {
            const futureTemplate = template;
            futureTemplate.inputs[index].value = time.utc().format();
            setTemplate(futureTemplate);
          }}
          autoFocus
          defaultValue={input.value ? moment(input.value) : ''}
          style={{ width: '100%' }}
          format="HH:mm"
        />
      </TimePickerWrapper>
    </>
  );

  const renderSelect = (input, index) => (
    <>
      <SelectWeb
        style={input.style}
        label={`${renderRequiredLabel(input)}${input.label}`}
        items={input.data}
        defaultIndex={
            input.data.findIndex((object) => {
              if (input.value) {
                return object.id === input.value.id;
              }
            }) !== -1
              ? input.data.find((object) => {
                if (input.value) {
                  return object.id === input.value.id;
                }
              }).id
              : null
          }
        keyValue={input.keyValue}
        keyName={input.keyName}
        onTextChange={(e) => {}}
        placeholder={input.placeholder}
        onItemSelect={(item) => {
          const futureTemplate = template;
          futureTemplate.inputs[index].value = item;
          setTemplate(futureTemplate);
          validateFields();
          forceUpdate();
        }}
      />
    </>
  );

  const renderImage = (form, index) => {
    if (form.photos.length === 1) {
      return (
        <>
          <ImagePreviewComponent
            style={form.style}
            label={`${renderRequiredLabel(form)}${form.general_label}`}
            title={`${renderRequiredLabel(form)}${form.photos[0].label}`}
            setPhotoURI={(newPhoto) => {
              const futureTemplate = template;
              futureTemplate.inputs[index].photos[0].value = {};
              futureTemplate.inputs[index].photos[0].value.base64 = newPhoto;
              setTemplate(futureTemplate);
              validateFields();
              forceUpdate();
            }}
            photoURI={form.photos[0].value?.base64 || form.photos[0].value}
          />
        </>
      );
    }
    return (
      <>
        <MultipleImagesPreview
          style={form.style}
          label={`${renderRequiredLabel(form)}${form.general_label}`}
          photosList={form.photos}
          onHandleSave={(newPhotos) => {
            const futureTemplate = template;
            futureTemplate.inputs[index].photos = newPhotos;
            setTemplate(futureTemplate);
            validateFields();
            forceUpdate();
          }}
          photoURI={form.photos[0].value}
        />
      </>
    );
  };

  if (!template) {
    return <Loader />;
  }

  const isVisible = (input) => {
    if (input.isVisible && input.isVisible.value === 0) {
      return false;
    }

    if (input.isVisible && input.isVisible.value === 2) {
      const conditionItem = getByRef(customTemplate, input.isVisible.condition.ref);

      if (JSON.stringify(conditionItem.value) !== JSON.stringify(input.isVisible.condition.value)) {
        return false;
      }
    }

    return true;
  };

  // Not in app
  const showAlert = (title = '', subtitle = '', buttons = []) => {
    setCustomAlert(
      <CustomAlert className="CustomAlert">
        <div className="wrapper">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>

          <div className="buttonsWrapper">
            {buttons.map((button) => (
              <button
                onClick={() => {
                  button.onPress();
                  setCustomAlert(null);
                }}
              >
                {button.text}
              </button>
            ))}

            <button
              onClick={() => {
                setCustomAlert(null);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomAlert>,
    );
  };

  return (
    <StepCustomWrapper ref={pageWrapper} className="StepCustomWrapper">
      {/* <div style={{ padding: 32, backgroundColor: "#fff", paddingTop: 32 }}>
          <h1 style={{ fontSize: 24, fontWeight: "bold" }}>Pasul 1</h1>
          <h2 style={{ fontSize: 16, fontWeight: "300" }}>Alege un asigurator</h2>
        </div> */}

      {customAlert && customAlert}
      <HeaderSteps
        step={step}
        setStep={setStep}
        setTemplate={setTemplate}
        title={template && template.title}
        subtitle={template && template.subtitle}
        procent={(step * 100) / customTemplate.form.length}
      />
      <FormContent className="FormContent">
        {template
          && template.inputs
          && template.inputs.map((input, index) => {
            // Check if is visible

            if (!isVisible(input)) {
              return null;
            }
            // Render the components
            if (input.type === 'text') {
              return renderText(input, index);
            }
            if (input.type === 'textarea') {
              return renderTextArea(input, index);
            }
            if (input.type === 'date') {
              return renderDate(input, index);
            }
            if (input.type === 'time') {
              return renderTime(input, index);
            }
            if (input.type === 'radio') {
              return renderRadio(input, index);
            }
            if (input.type === 'select') {
              return renderSelect(input, index);
            }

            if (input.type === 'photo') {
              return renderImage(input, index);
            }
            if (input.type === 'file') {
              return renderUploader(input, index);
            }
          })}
        <Button
          onClick={() => {
            if (template.nextStepContition) {
              return showAlert(
                template.nextStepContition.title,
                template.nextStepContition.subtitle,

                template.nextStepContition.buttons.map((button) => ({
                  text: button.text,
                  onPress: () => {
                    // Set next step
                    storageSave('currentStep', button.step);

                    // Find next step template and set it
                    const index = customTemplate.form.findIndex((x) => x.step === template.step);
                    customTemplate.form[index] = template;

                    storageSave('customTemplate', customTemplate);
                    setStep(button.step);
                    setTemplate(searchStep(customTemplate.form, button.step));

                    pageWrapper.current.scrollTo({ top: 0 });
                    history.push({ pathname: `/step-custom/${button.step}` });
                  },
                  style: button.style,
                })),
              );
            }

            // Set next step
            storageSave('currentStep', step + 1);

            // Find next step template and set it
            const index = customTemplate.form.findIndex((x) => x.step === template.step);
            customTemplate.form[index] = template;

            storageSave('customTemplate', customTemplate);
            setStep(template.nextStep);
            setTemplate(searchStep(customTemplate.form, template.nextStep));

            pageWrapper.current.scrollTo({ top: 0 });
            history.push({ pathname: `/step-custom/${template.nextStep}` });
          }}
          className="primary"
          title="Urmatorul pas"
          disabled={isAdmin ? false : !canContinue}
        />
      </FormContent>
    </StepCustomWrapper>
  );
}
