import React, { useContext } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { CheckCircleOutlined } from '@ant-design/icons';
import { AppContext } from '../../context/app.context';

import {
  PickRoleWrapper, WorkEnvWrapper, RolesWrapper, IconWrapper, ButtonWrapper, RoleWrapper 
} from './pick-role.styled';
import { Logo } from '../../components';
import {
  URL_DEV, URL_LIVE, URL_TEST, URL_DEMO, PUBLISHED_VERSION 
} from '../../constants';

export default function PickRolePage() {
  const {
    loginRole, setLoginRole, user, SET_API_URL, API_URL 
  } = useContext(AppContext);
  const history = useHistory();

  if (user) {
    return <Redirect to="/dashboard" />;
  }

  const renderEnvWrapper = () => (
    <WorkEnvWrapper className="WorkEnvWrapper">
      <button
        style={{
          backgroundColor: API_URL === URL_TEST ? '#10e4e4' : 'transparent',
        }}
        onClick={() => SET_API_URL(URL_TEST)}
      >
        TEST
      </button>
      <button
        style={{
          backgroundColor: API_URL === URL_DEMO ? '#10e4e4' : 'transparent',
        }}
        onClick={() => SET_API_URL(URL_DEMO)}
      >
        DEMO
      </button>
      <button
        style={{
          backgroundColor: API_URL === URL_DEV ? '#10e4e4' : 'transparent',
        }}
        onClick={() => SET_API_URL(URL_DEV)}
      >
        DEV
      </button>
      <button
        style={{
          backgroundColor: API_URL === URL_LIVE ? '#10e4e4' : 'transparent',
        }}
        onClick={() => SET_API_URL(URL_LIVE)}
      >
        LIVE
      </button>
    </WorkEnvWrapper>
  );

  return (
    <PickRoleWrapper className="PickRoleWrapper">
      <Logo />

      {!PUBLISHED_VERSION && renderEnvWrapper()}

      <RolesWrapper className="RolesWrapper">
        <RoleWrapper
          className={loginRole === 'client' ? 'active' : ''}
          onClick={() => {
            if (loginRole === 'client') {
            } else {
              setLoginRole('client');
            }
          }}
        >
          {loginRole === 'client' && (
            <IconWrapper className="IconWrapper">
              <CheckCircleOutlined />
            </IconWrapper>
          )}
          Sunt Client
        </RoleWrapper>
        <RoleWrapper
          className={loginRole === 'constatator' ? 'active' : ''}
          onClick={() => {
            if (loginRole === 'constatator') {
            } else {
              setLoginRole('constatator');
            }
          }}
        >
          {loginRole === 'constatator' && (
            <IconWrapper className="IconWrapper">
              <CheckCircleOutlined />
            </IconWrapper>
          )}
          Sunt Constatator
        </RoleWrapper>
        {loginRole && (
          <ButtonWrapper
            type="primary"
            onClick={() => {
              history.push('login');
            }}
          >
            Inainte
          </ButtonWrapper>
        )}
      </RolesWrapper>
    </PickRoleWrapper>
  );
}
