import React, { useState, useEffect } from "react";
import { Camara } from "../../components";
import {
  ImageFullScreenWrapper,
  ImageThumbnailWrapper,
  ImageFullScreenHeader,
  IconTitleWrapper,
  CameraTitleWrapper,
  ImageFullScreenActionWrapper,
  ImageWrapper,
} from "./image-preview.style";
import { CloseOutlined } from "@ant-design/icons";

import { CameraOutlined } from "@ant-design/icons";
import Button from "../button/button.component";

export default function ImagePreviewComponent({
  withPreview,
  fullScreen,
  photoURI,
  setPhotoURI,
  icon,
  title,
  disabled,
  overflowPhoto,
  onlyViewMode,
  multipleMode,
  withoutFileName,
  style,
}) {
  const [isFullScreenMode, setIsFullScreenMode] = useState(fullScreen);
  const [camaraVisible, setCamaraVisible] = useState(false);
  const [canRenderCamera, setCanRenderCamera] = useState(false);

  const handleRetakePhoto = () => {
    setPhotoURI(null);
    setCamaraVisible(true);
  };

  useEffect(() => {
    if (camaraVisible) {
      setCanRenderCamera(true);
    } else {
      setCanRenderCamera(false);
    }
  }, [camaraVisible]);
  const renderFullScreenPreview = () => {
    return (
      <ImageFullScreenWrapper className="ImageFullScreenWrapper">
        <ImageFullScreenHeader className="ImageFullScreenHeader">
          <IconTitleWrapper className="IconTitleWrapper" onClick={() => setIsFullScreenMode(false)}>
            <CloseOutlined />
          </IconTitleWrapper>
          {title && <CameraTitleWrapper className="CameraTitleWrapper">{title}</CameraTitleWrapper>}
        </ImageFullScreenHeader>

        <img alt={title && title} src={photoURI} />

        {!disabled && (
          <ImageFullScreenActionWrapper className="ImageFullScreenActionWrapper">
            <Button
              className="link-danger"
              onClick={() => {
                setPhotoURI(null);
                setIsFullScreenMode(false);
              }}
              title="Sterge poza"
            />

            <Button className="retake-photo" onClick={handleRetakePhoto} title="Refă poza" />
          </ImageFullScreenActionWrapper>
        )}
      </ImageFullScreenWrapper>
    );
  };
  const renderThumbnailPreview = () => {
    return (
      <ImageWrapper style={style} className={multipleMode ? "ImageWrapper multiple" : "ImageWrapper"}>
        {title && !multipleMode && <label className="title">{title}</label>}
        <ImageThumbnailWrapper className="ImageThumbnailWrapper" onClick={() => setIsFullScreenMode(true)}>
          <img src={photoURI} alt={title && title} />
          {title && !withoutFileName && <span className="ImageTitle">{title}</span>}
        </ImageThumbnailWrapper>
      </ImageWrapper>
    );
  };

  const renderEmptyPreview = () => {
    return (
      <ImageWrapper style={style} className={multipleMode ? "ImageWrapper multiple" : "ImageWrapper"}>
        {title && !multipleMode && <label className="title">{title}</label>}
        <ImageThumbnailWrapper
          className="ImageThumbnailWrapper"
          onClick={() => {
            setCamaraVisible(true);
          }}
        >
          {overflowPhoto && <img alt="Overlay template" style={{ position: "absolute", top: 0, opacity: 0.5 }} src={overflowPhoto} />}
          {!overflowPhoto ? icon ? icon : <CameraOutlined /> : null}
          {title && <label>{title}</label>}
        </ImageThumbnailWrapper>
      </ImageWrapper>
    );
  };

  if (canRenderCamera) {
    return (
      <Camara
        isSilentMode
        withPreview={withPreview ? true : false}
        overlay={overflowPhoto}
        title={title}
        idealFacingMode="FACING_MODES.ENVIRONMENT"
        setImagePreviewFullScreenMode={setIsFullScreenMode}
        setPhotoURI={setPhotoURI}
        camaraVisible={camaraVisible}
        setCamaraVisible={setCamaraVisible}
      />
    );
  }

  if (!photoURI || photoURI?.base64 === null) {
    return renderEmptyPreview();
  }

  return isFullScreenMode ? renderFullScreenPreview() : renderThumbnailPreview();
}
