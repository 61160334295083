import styled from "styled-components";

export const GaragePageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: #f1f0f2;
`;

export const SearchContent = styled.div`
  padding: 32px 24px 0px;

  input {
    border-radius: 33px 0px 0px 33px;
    height: 37px;
  }

  .ant-input-group-addon {
    border-radius: 0px 33px 33px 0px;
    overflow: hidden;
  }
  .ant-btn-primary {
    background: rgb(15, 228, 228);
    border-color: rgb(15, 228, 228);
    height: 37px;
  }
`;

export const InputContent = styled.input`
  position: relative;
  width: 80%;
  border: 1px solid gray;
`;

export const CarContent = styled.div`
  padding: 32px 24px;
`;
