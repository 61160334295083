import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HeaderSteps, Button, Uploader, Loader } from "../../../../components";
import { AppContext } from "../../../../context/app.context";
import { CivSubPageWrapper, CivSubPageContent } from "./civ.style";

export default function CivSubpage() {
  const { user, getUserDocuments, saveUserDocuments, selectedCarFromGarage } = useContext(AppContext);

  const [document, setDocument] = useState(null);

  const [pageLoaded, setPageLoaded] = useState(false);
  const history = useHistory();
  const fetchDocument = async () => {
    const res = await getUserDocuments("civ", selectedCarFromGarage.id);

    if (res.error) {
      return;
    }

    setDocument(res.data.document);

    setPageLoaded(true);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: "civ",
      id_user: user.user.id,
      document: document,
      car_id: selectedCarFromGarage.id,
    });
    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }

  return (
    <CivSubPageWrapper className="CivSubPageWrapper">
      <HeaderSteps title="CIV" subtitle={`Mașină ${selectedCarFromGarage.registration_number}`} />

      <CivSubPageContent className="CivSubPageContent">
        <Uploader label="Document CIV" document={document} setDocument={setDocument} />
        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </CivSubPageContent>
    </CivSubPageWrapper>
  );
}
