import React, { useContext, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { AppContext } from '../../context/app.context';
import {
  InputWeb, SelectWeb, Button, HeaderSteps, Loader 
} from '../../components';
import { storageGet } from '../../storage/localStorage';

import { EditCarPageContent, EditCarPageWrapper, SectionWrapper } from './edit-car.style';

export default function CarEditScreen() {
  const location = useLocation();
  const {
    makes, updateCar, isLoading, fuelTypes, bodyTypes 
  } = useContext(AppContext);
  const car = storageGet('client_cars').find((car) => car.id === location.search.substring(4));

  const [models, setModels] = useState(makes[car.make_id].models);

  const history = useHistory();
  // Fix where car fuel is null
  // If is null it return an empty array []
  const localFuelIndex = fuelTypes.filter((fuel) => fuel.name === car.fuel_type);
  const [fuel, setFuel] = useState(localFuelIndex.length > 0 ? localFuelIndex[0].id : null);

  const [carImage] = useState(null);
  const [vin, setVin] = useState(car.vin || null);
  const [number, setNumber] = useState(car.registration_number || null);
  const [year, setYear] = useState(car.model_year || null);
  const [bodyType, setBodyType] = useState(car.type_id || null);
  const [selectedMake, setSelectedMake] = useState(car.make_id || null);

  const getModelID = () => {
    const foundedCar = makes[car.make_id].models.filter((model) => model.name === car.model);

    if (foundedCar.length > 0) {
      return foundedCar[0].id;
    }
    return 0;
  };

  // Get the id of model from the name;
  const [selectedModel, setSelectedModel] = useState(getModelID());

  const [localBodyTypes] = useState(
    bodyTypes.map((bodyType) => bodyType.name)
  );

  const renderLoading = () => <Loader />;

  const handleFormSubmit = () => {
    const formData = {
      id: car.id,
      body_id: bodyType,
      fuel_type: fuel,
      make_id: selectedMake,
      model_id: selectedModel,
      model_year: year,
      registration_number: number,
      vin,
    };
    updateCar(formData);
    history.push('/garage');
  };

  return (
    <EditCarPageWrapper
      style={{
        backgroundColor: '#f2f2f2',
        paddingBottom: 32,
        flex: 1,
      }}
    >
      <HeaderSteps title="Editează masina" subtitle={`Masina #${car.id}`} />

      {isLoading && renderLoading()}

      <EditCarPageContent>
        <div>
          {carImage && (
            <div
              style={{
                padding: 32,
                borderRadius: 16,
                marginBottom: 32,
                backgroundColor: '#fff',
              }}
            >
              <img style={{ height: 150, width: '100%' }} alt="car" source={{ uri: `data:image/jpg;base64,${carImage}` }} />
            </div>
          )}

          <SectionWrapper className="SectionWrapper">
            <InputWeb
              autoCapitalize="characters"
              allowClear
              placeholder="UU1B522046149XXXX"
              label="Vin"
              text={vin}
              onChangeText={(e) => setVin(e)}
            />
          </SectionWrapper>

          {makes && (
            <SectionWrapper className="SectionWrapper">
              <SelectWeb
                label="Marca"
                items={Object.values(makes)}
                placeholder="Alege o marca"
                keyValue="id"
                key={selectedMake}
                defaultIndex={selectedMake}
                onItemSelect={(e) => {
                  setSelectedModel(undefined);
                  setSelectedMake(e.id);
                  setModels(makes[e.id].models);
                }}
              />
            </SectionWrapper>
          )}
          <SectionWrapper className="SectionWrapper">
            <SelectWeb
              label="Model"
              items={models}
              key={selectedMake}
              defaultIndex={selectedModel}
              keyValue="id"
              keyName="name"
              onTextChange={(e) => console.log(e)}
              placeholder="Alege un model"
              onItemSelect={(model) => {
                setSelectedModel(model.id);
              }}
            />
          </SectionWrapper>

          <SectionWrapper className="SectionWrapper">
            <SelectWeb
              label="Caroserie"
              key={selectedMake}
              keyValue="id"
              keyName="name"
              defaultIndex={bodyType}
              items={localBodyTypes}
              placeholder="Alege o caroserie"
              onTextChange={(e) => console.log(e)}
              onItemSelect={(e) => {
                setBodyType(e.id);
              }}
            />
          </SectionWrapper>

          <SectionWrapper className="SectionWrapper">
            <SelectWeb
              label="Combustibil"
              items={fuelTypes}
              defaultIndex={fuel}
              keyValue="id"
              keyName="name"
              onTextChange={(e) => console.log(e)}
              placeholder="Alege un combustibil"
              onItemSelect={(e) => {
                setFuel(e.id);
              }}
            />
          </SectionWrapper>
        </div>

        <SectionWrapper className="SectionWrapper">
          <InputWeb
            allowClear
            placeholder="SB20MCA"
            autoCapitalize="characters"
            label="Numar masina"
            text={number}
            onChangeText={setNumber}
          />
        </SectionWrapper>
        <SectionWrapper className="SectionWrapper">
          <InputWeb allowClear placeholder="2020" label="An masina" text={year} onChangeText={setYear} />
        </SectionWrapper>

        <Button
          onClick={() => {
            handleFormSubmit();
            // navigation.goBack();
          }}
          className="primary"
          disabled={!vin || !year || !bodyType || !number || !fuel || !selectedMake || !selectedModel}
          active
          title={
            !vin || !year || !bodyType || !number || !fuel || !selectedMake || !selectedModel ? 'Completează tot' : 'Salvează modificările'
          }
        />
      </EditCarPageContent>
    </EditCarPageWrapper>
  );
}
