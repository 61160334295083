import styled from "styled-components";

export const CamaraWrapper = styled.div`
  height: calc(100% - 64px);
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;

  img {
    width: 100%;
  }

  .react-html5-camera-photo {
    width: 100%;
    position: absolute;
    height: 100%;
    video {
      width: 100%;
      object-fit: contain;
      height: 100%;
      background: black;
    }
  }
`;

export const ConfirmationPreviewWrapper = styled.div`
  background: #101010;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const ConfirmationActionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  backdrop-filter: blur(61px);
  background: #00000017;
  padding: 32px;
  flex-direction: column;

  .ButtonWrapper {
    margin-top: 16px;
  }
`;

export const CamaraHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  backdrop-filter: blur(61px);
  background: #00000017;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 16px;
  color: #fff;
`;

export const SelectorWrapper = styled.div`
  h2 {
    text-align: center;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 50px;
  }
  background: #fff;
  border-radius: 80px 0px 0px 0px;
  width: 100%;
  position: absolute;
  bottom: 0;
  justify-self: self-start;
  margin-top: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
export const OptionWrapper = styled.div`
  width: 100%;
  line-height: 50px !important;
  height: 50px !important;
  border-radius: 0px 0px 0px 50px;
  border-bottom: 1px solid #d3cccc;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  text-transform: capitalize;
  line-height: 50px;
`;
export const CommentTag = styled.div`
  position: absolute;
  background: #00000080;
  color: rgb(255, 255, 255);
  padding: 2px 32px;
  backdrop-filter: blur(40px);
  border-radius: 0px 33px 33px;
  box-shadow: 0px 0px 20px -7px #000;
`;

export const TutorialWrapper = styled.div`
  position: absolute;
  z-index: 99;
  width: 80%;
  border: 1px solid #eee;
  padding: 32px;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 20%;
    margin-bottom: 32px;
    height: auto;
  }
`;

export const ImageWrapper = styled.div`
  width: 100%;
  position: relative;

  img {
    max-height: calc(100% - 64px);
    overflow: hidden;
  }
`;

export const IconWrapper = styled.span`
  border-radius: 50%;
  background: #ffffff2b;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    color: white !important;
  }
`;

export const CamaraHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const CameraTitleWrapper = styled.span``;

export const CameraSubTitleWrapper = styled.span``;

export const SelectorOption = styled.div`
  border-top: 1px solid #d3cccc;
`;
