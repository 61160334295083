const LOCAL_PREFIX = 'carFix_';

export const storageGet = (item) => {
  if (!localStorage[`${LOCAL_PREFIX}${item}`]) {
    return null;
  }

  return JSON.parse(localStorage[LOCAL_PREFIX + item]);
};

export const storageSave = (itemName, item) => {
  localStorage[`${LOCAL_PREFIX}${itemName}`] = JSON.stringify(item);
  return true;
};

export const storage_delete_item = (itemName) => {
  localStorage.removeItem(`${LOCAL_PREFIX}${itemName}`);
  return true;
};

export const storage_delete_all = () => {
  const keys = Object.keys(localStorage);
  let i = keys.length;

  while (i--) {
    if (keys[i].includes(LOCAL_PREFIX)) {
      localStorage.removeItem(keys[i]);
    }
  }

  return true;
};
