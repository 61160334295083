import styled from "styled-components";

export const SelectorWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const RadioComponentWrapper = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 22px 42px 32px 42px;
  width: 100%;
  label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 16px;
  }
`;
export const RadioWrapper = styled.div`
  height: 37px;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  border-radius: 33px;
  color: #767676;

  p {
    margin: 0;
  }

  &.active {
    border: none;
    background: #10e4e4;
    color: #181726;
  }
`;
