import styled from "styled-components";

export const ResetPasswordWrapper = styled.div`
  padding: 92px 32px 32px 32px;
  height: 100vh;
  display: flex;
  flex-direction: column;

  .LogoWrapper {
    width: 50%;
    margin: 0 auto 50px auto;
  }

  .InputWrapper {
    padding: 0;
    margin-bottom: 20px;
  }

  .ButtonWrapper {
    margin-top: 20px;
  }
`;
