import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'antd/lib/modal/Modal';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../context/app.context';
import {
  InputWeb, SelectWeb, Loader, Button, HeaderSteps, FancyHeader 
} from '../../components';
import {
  AddCarPageWrapper, SectionWrapper, AddCarPageContent, ModalContentWrapper 
} from './add-car.style';

export default function AddCarPage({ navigation }) {
  const {
    user, makes, getMakes, setIsLoading, addNewCar, isLoading, fuelTypes, bodyTypes, API_URL 
  } = useContext(AppContext);

  // Local state
  const [models, setModels] = useState([]);

  const [carImage, setCarImage] = useState(null);
  const [vin, setVin] = useState('');
  const [number, setNumber] = useState(undefined);
  const [year, setYear] = useState(undefined);
  const [fuel, setFuel] = useState(undefined);
  const [bodyType, setBodyType] = useState(undefined);
  const [selectedMake, setSelectedMake] = useState(undefined);
  const [selectedModel, setSelectedModel] = useState(undefined);

  const [localBodyTypes, setLocalBodyTypes] = useState([]);
  const [carSelectorModal, setCarSelectorModal] = useState(null);
  const [foundedCars, setFoundedCars] = useState([]);

  const history = useHistory();
  useEffect(() => {
    const futureLocalBodyTypes = [];

    if (bodyTypes) {
      bodyTypes.map((bType) => {
        futureLocalBodyTypes.push(bType.name);
      });
    }

    setLocalBodyTypes(futureLocalBodyTypes);
  }, [bodyTypes]);

  useEffect(() => {
    if (!makes) {
      getMakes(user.jwt, user.user.id);
    }
  }, [makes]);

  const handleFormSubmit = () => {
    const formData = {
      body_id: bodyType,
      fuel_type: fuel,
      avatar: carImage,
      make_id: selectedMake,
      model_id: selectedModel,
      model_year: year,
      registration_number: number,
      vin,
    };

    const carWasAdded = addNewCar(formData).then((res) => {
      if (res) {
        history.goBack();
      }

      setIsLoading(false);
    });
  };

  // If vin return more than 1 car;
  const showCarSelector = (vinResult) => {
    const results = vinResult.data.message.carDetails;

    if (results.length > 1) {
      setCarSelectorModal(true);
      setFoundedCars(results);
    } else {
      // Only one car from vin decoder
      setModels(makes[results[0].makeId].models);
      setSelectedMake(results[0].makeId);
      setCarImage(results[0].modelImage);
      setSelectedModel(results[0].modelId);
      setSelectedModel(results[0].modelId);
    }
    setIsLoading(false);
  };

  const handleVinDecoder = () => {
    // https://api.carfix.sergiumihai.dev.ascensys.ro/v1/gt-motive/vin-decoder

    setIsLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append('vin', vin);

    axios({
      method: 'post',
      url: `${API_URL}/gt-motive/vin-decoder`,
      data: bodyFormData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        if (response.data.error) {
          message.error(response.data.message);
        }

        showCarSelector(response);
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
        setIsLoading(false);
      });
  };

  return (
    <AddCarPageWrapper className="AddCarPageWrapper">
      <HeaderSteps title="Masina noua" subtitle="Adauga o masina noua" />

      {isLoading && <Loader />}
      {/* Modal car selector */}
      <Modal
        visible={carSelectorModal}
        footer={null}
        onCancel={() => {
          setCarSelectorModal(false);
        }}
      >
        <ModalContentWrapper className="ModalContentWrapper">
          <FancyHeader title="VIN" subtitle="Am gasit mai multe masini" />

          {foundedCars
            && foundedCars.map((car, index) => {
              if (car.error) {
                return (
                  <div className="founded-car">
                    <p>{car.makeName}</p>
                    <p>{car.message}</p>
                  </div>
                );
              }

              return (
                <div
                  onClick={() => {
                    setModels(makes[car.makeId].models);
                    setSelectedModel(car.modelId);
                    setSelectedMake(car.makeId);
                    setCarImage(car.modelImage);
                    setCarSelectorModal(false);
                  }}
                >
                  <div key={index}>
                    <div className="founded-car">
                      <h3>{car.makeName}</h3>
                      <p>{car.modelName}</p>
                    </div>
                  </div>
                </div>
              );
            })}
        </ModalContentWrapper>
      </Modal>

      <AddCarPageContent>
        {carImage && (
          <div
            style={{
              padding: 32,
              borderRadius: 16,
              marginBottom: 32,
              backgroundColor: '#fff',
            }}
          >
            <img style={{ height: 150, width: '100%' }} alt="car" source={{ uri: `data:image/jpg;base64,${carImage}` }} />
          </div>
        )}

        <SectionWrapper>
          
          <InputWeb
            allowClear
            placeholder="UU1B522046149XXXX"
            label="Vin"
            text={vin}
            autoCapitalize="characters"
            onChangeText={(e) => {
              setVin(`${e}`.toUpperCase());
            }}
          />

          {vin && vin.length === 17 ? (
            <Button
              className="primary"
              onClick={() => {
                handleVinDecoder();
              }}
              active
              title="Vin decoder"
            />
          ) : null}
        </SectionWrapper>

        {makes && (
          <SectionWrapper>
            <SelectWeb
              label="Marca"
              items={Object.values(makes)}
              placeholder="Alege o marca"
              keyValue="id"
              key={selectedMake}
              defaultIndex={selectedMake}
              onItemSelect={(e) => {
                setSelectedModel(undefined);
                setSelectedMake(e.id);
                setModels(makes[e.id].models);
              }}
            />
          </SectionWrapper>
        )}
        <SectionWrapper key={selectedMake}>
          <SelectWeb
            key={selectedModel}
            label="Model"
            items={models}
            defaultIndex={selectedModel}
            keyValue="id"
            keyName="name"
            onTextChange={(e) => {}}
            placeholder="Alege un model"
            onItemSelect={(model) => {
              setSelectedModel(model.id);
            }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <SelectWeb
            label="Caroserie"
            key={selectedMake}
            keyValue="id"
            keyName="name"
            items={localBodyTypes}
            defaultIndex={bodyType}
            placeholder="Alege o caroserie"
            onTextChange={(e) => console.log(e)}
            onItemSelect={(e) => {
              setBodyType(e.id);
            }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <SelectWeb
            label="Combustibil"
            items={fuelTypes}
            keyName="name"
            keyValue="id"
            onTextChange={(e) => {}}
            placeholder="Alege un combustibil"
            onItemSelect={(e) => {
              setFuel(e.id);
            }}
          />
        </SectionWrapper>

        <SectionWrapper>
          <InputWeb
            allowClear
            placeholder="SB20MCA"
            autoCapitalize="characters"
            label="Numar masina"
            text={number}
            onChangeText={(e) => {
              setNumber(`${e}`.toUpperCase());
            }}
          />
        </SectionWrapper>
        <SectionWrapper>
          <InputWeb keyboardType="numeric" allowClear placeholder="2020" label="An masina" text={year} onChangeText={setYear} />
        </SectionWrapper>

        <Button
          className="primary"
          onClick={() => {
            handleFormSubmit();
          }}
          isLoading={isLoading}
          disabled={!vin || !year || !bodyType || !number || !fuel || !selectedMake || !selectedModel}
          title={!vin || !year || !bodyType || !number || !fuel || !selectedMake || !selectedModel ? 'Completează tot' : 'Adaugă mașină'}
        />
      </AddCarPageContent>
    </AddCarPageWrapper>
  );
}
