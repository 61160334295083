import React from "react";
import { SelectorWrapper, RadioWrapper, RadioComponentWrapper } from "./radio.styled";

export default function SelectRadio({
  style,
  label,
  onItemSelect,
  placeholder,
  defaultValue,
  items,
  keyValue = "value",
  keyName = "name",
}) {
  const handlePress = (e) => {
    onItemSelect(e);
  };

  return (
    <RadioComponentWrapper style={style} className="RadioComponentWrapper">
      {label && <label>{label}</label>}

      <SelectorWrapper>
        {items.map((radio) => (
          <RadioWrapper
            key={radio[keyValue]}
            onClick={() => handlePress(radio)}
            className={radio[keyValue] === defaultValue ? "active" : "normal"}
          >
            <p>{radio[keyName]}</p>
          </RadioWrapper>
        ))}
      </SelectorWrapper>
    </RadioComponentWrapper>
  );
}
