import React, { useContext, useEffect } from 'react';
import { FancyHeader, Notification } from '../../../../components';

import { NotificationPageWrapper } from './notification.style';
import { AppContext } from '../../../../context/app.context';

export default function NotificationPage() {
  const { notifications, fetchNotifications } = useContext(AppContext);

  useEffect(() => {
    if (notifications.length <= 0) {
      fetchNotifications();
    }
  }, []);
  return (
    <NotificationPageWrapper>
      <FancyHeader title="Notificarile tale" subtitle="Aici vine un subtitlu" />

      {notifications.map((notification) => (
        <Notification notification={notification} key={notification.id} />
      ))}
    </NotificationPageWrapper>
  );
}
