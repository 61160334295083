import React from "react";
import { DatePicker } from "antd";
import { TimePickerWrapper } from "../../pages/new-damage/subpages/step-custom/step-custom.styled";
import moment from "moment";

export default function DatePickerWeb({ style, value, setValue, label }) {
  return (
    <TimePickerWrapper style={style} className="TimePickerWrapper">
      <label>{label}</label>
      <DatePicker
        format="DD-MM-YYYY"
        // disabledDate={(current) => {
        //   return current && current > moment().endOf("day");
        // }}
        autoFocus
        inputReadOnly
        onChange={(d, dateString) => {
          let date = dateString.split("-");
          setValue(Date.parse(`${date[1]}-${date[0]}-${date[2]}`) / 1000);
        }}
        defaultValue={value ? moment.unix(value) : moment()}
        style={{ width: "100%" }}
      />
    </TimePickerWrapper>
  );
}
