import styled from 'styled-components';

export const Map2DWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: #f1f0f2;
  position: relative;
  .ImagePreviewWrapper {
    width: 100%;
  }
  .ImageThumbnailWrapper {
    background: #f8f8f8;
    border: 2px dashed #707070;
    border-radius: 20px;
    .anticon {
      font-size: 28px;
    }
    .ImageTitle {
      background: transparent;
      bottom: 15%;
      color: #767676;
      -webkit-backdrop-filter: none;
      backdrop-filter: none;
    }
  }
  .submit_button {
    margin-top: 64px;
  }
  .ant-form-item-label {
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    text-align: left;
  }
`;
export const CarMapWrapper = styled.div`
  .react-transform-component {
    height: calc(88vh - 120px);
    background: #fff;
    border-radius: 20px;
    margin: 0 auto;
  }

  .react-transform-element {
    max-height: 100vh !important;
    max-width: 360px;
  }

  img {
    width: 100%;
    height: 100%;
    opacity: 0.9;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: #10e4e4;
    position: absolute;
  }
  padding: 15px 15px;
`;

export const PreviewPhotoWrapper = styled.div`
  height: calc(100% - 64px);
  position: fixed;
  width: 100%;
  z-index: 99;
  top: 0;
  left: 0;
  .react-html5-camera-photo {
    width: 100%;
    video {
      width: 100%;
      object-fit: contain;
      height: calc(100% - 64px);
      background: black;
    }
  }

  img {
    width: 100%;
  }
`;

export const PreviewPhotoHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  backdrop-filter: blur(61px);
  background: #00000017;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 16px;
  color: #fff;
`;

export const CameraTitleWrapper = styled.span``;

export const CameraSubTitleWrapper = styled.span``;

export const ConfirmationPreviewWrapper = styled.div`
  background: #101010;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  flex-direction: column;
  display: block !important;
`;

export const ConfirmationActionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  backdrop-filter: blur(61px);
  background: #00000017;
  padding: 32px;
`;
export const CommentTag = styled.div`
  position: absolute;
  background: #00000080;
  color: rgb(255, 255, 255);
  padding: 2px 32px;
  backdrop-filter: blur(40px);
  border-radius: 0px 33px 33px;
  box-shadow: 0px 0px 20px -7px #000;
`;

export const ButtonWrapper = styled.div`
  bottom: 32px;
  width: 100%;
  position: absolute;
  padding: 0px 32px;

  display:flex;
  justify-content:center;


  button {
    max-width:340px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  max-height: calc(100% - 64px);
  overflow: scroll;
  .ant-carousel {
`;

export const IconTitleWrapper = styled.span`
  border-radius: 50%;
  background: #ffffff2b;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    color: white !important;
  }
`;

export const DeleteButtonWrapper = styled.button`
  background: white !important;
  height: 45px !important;
  border-radius: 52px;
  width: 85% !important;
  text-align: center;
  text-transform: uppercase;
  border: 0;
  position: absolute !important;
  top: 0.8;
  top: -70px !important;
`;

export const PreviewPhotoHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const RetakeButton = styled.button`
  height: 45px !important;
  border-radius: 52px;
  width: 85% !important;
  position: relative;
  top: -5px !important;
`;

export const CarouselWrapper = styled.div`
  position: relative;
  top: 230px;
  width: 100%;
`;
