import React, { useState } from 'react';
import { CameraOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import {
  StepGeneralPhotosWrapper, ButtonWrapper, ImagesWrapper, ImagePreviewWrapper, LabelWrapper,
} from './step-detail-2d.styled';

import { HeaderSteps, ImagePreviewComponent } from '../../../../components';

export default function StepDetail2D() {
  const [overallPhoto, setOverallPhoto] = useState(null);

  const [autoTemplate, setAutotemplate] = useState(null);

  return (
    <StepGeneralPhotosWrapper>
      <HeaderSteps title="Fata" subtitle="Poza de ansamblu si de detaliu" procent="80" />

      <ImagesWrapper>
        {autoTemplate
          && autoTemplate.map((template, index) => (
            <ImagePreviewWrapper key={template.id} className="ImagePreviewWrapper">
              <Form.Item>
                <LabelWrapper>{template.description}</LabelWrapper>
                <ImagePreviewComponent
                  icon={<CameraOutlined />}
                  title="Poza ansamblu"
                  setPhotoURI={setOverallPhoto}
                  photoURI={overallPhoto}
                />
              </Form.Item>
            </ImagePreviewWrapper>
          ))}
      </ImagesWrapper>
      <ButtonWrapper type="primary" onClick={() => {}} block shape="round">
        Confirm
      </ButtonWrapper>
    </StepGeneralPhotosWrapper>
  );
}
