import styled from "styled-components";

export const MyDocumentsPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
`;

export const MyDocumentsPageContent = styled.div`
  padding: 32px 24px;
`;
