import styled from 'styled-components';

export const CarPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: rgb(241, 240, 242);
  position: relative;

  .HeaderWrapper {
    position: sticky;
    z-index: 9999;
  }
`;

export const ButtonEditCarWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
  button {
    width: 100%;
    border-radius: 52px;
    margin-top: 32px;
    background: #10e4e4;
    color: #181726;
    font-size: 14px;
    line-height: 26px;
    border: 0;
    padding: 13px 0;
    display: inline-block;
    height: auto;
    font-weight: 500;
    text-transform: uppercase;
    &:hover {
      color: #10e4e4;
      background: #181726;
    }
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  background: rgb(24, 23, 38);
  padding: 92px 32px 32px;
  margin: -92px 0px 14px;
  border-radius: 0px 0px 0px 50px;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  position: relative;

  .car-info {
    margin-top: 32px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 18px;
    display: flex;
  }

  .edit-button {
    right: 16px;
    position: absolute;
    z-index: 999;
    background: rgb(16, 228, 228);
    border: none;
    color: rgb(24, 23, 38);
  }
`;
export const SectionWrapper = styled.div`
  margin-bottom: 32px;

  .ant-select {
    width: 100%;
  }

  h3 {
    color: #181726;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
`;

export const ViewCarPageContent = styled.div`
  padding: 24px 16px;
`;

export const TableWrapper = styled.div`
  background: rgb(255, 255, 255);
  padding: 16px;
  border-radius: 20px;
`;

export const TableRow = styled.div`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid rgba(153, 143, 162, 0.08);
  -webkit-box-align: center;
  align-items: center;
  height: 56px;
  margin-bottom: 6px;
  label {
    font-weight: 500;
    color: rgb(0, 0, 0);
    max-width: 50%;
    line-height: 14px;
  }

  span {
    font-weight: 300;
    text-align: right;
    width: 50%;

    display: flex;
    justify-content: flex-end;
  }
`;

export const ButtonsWrapper = styled.div`
  padding: 20;
  button {
    width: auto;
  }
`;
