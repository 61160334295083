import { message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Button, InputWeb, Logo } from '../../components';
import { AppContext } from '../../context/app.context';
import { ProtectedPageWrapper } from './protected.style';

const USER = 'wwwcarfixapp';
const PASS = 'carfixapplive';

export default function ProtectedPage() {
  const { setAppIsBlocked } = useContext(AppContext);
  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count > 10) {
      setAppIsBlocked(false);
    }
  }, [count]);

  const _handleClick = () => {
    if (username === USER && pass === PASS) {
      setAppIsBlocked(false);
    } else {
      message.error('User / Parola incorecta');
    }
  };

  return (
    <ProtectedPageWrapper>
      <Logo />

      <h1 onClick={() => setCount(count + 1)} style={{ textAlign: 'center' }}>
        Pagina este protejata
      </h1>

      <InputWeb
        style={{ padding: 0, marginBottom: 32 }}
        allowClear
        label='User'
        placeholder='Enter username...'
        text={username}
        onChangeText={(e) => {
          setUsername(e);
        }}
      />
      <InputWeb
        style={{ padding: 0, marginBottom: 32 }}
        allowClear
        label='Parola'
        text={pass}
        placeholder='Enter password...'
        onChangeText={(e) => {
          setPass(e);
        }}
      />
      <Button title='Deblocheaza' className='primary' onClick={_handleClick} />
    </ProtectedPageWrapper>
  );
}
