import styled from "styled-components";

export const ReviziiSubPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: #f2f2f2;
`;

export const ReviziiSubPageContent = styled.div`
  padding: 32px 14px;

  & > div {
    margin-bottom: 20px;
  }
`;
