import React from 'react';
import { useHistory } from 'react-router-dom';
import { DocumentListItemWrapper, UnsignedDocumentWrapper, SignedDocumentWrapper } from './document-list-item.styled';

export default function DocumentListItem({ document, onlyViewMode, onlyIcon }) {
  const history = useHistory();

  const renderSignedBadge = () => {
    if (
      document.filename === 'RegieProprieRCA'
      || document.filename === 'RegieProprieCASCO'
    ) {
      return <span className="signature">Da</span>;
    }
    return <span className="signature">{document.signature ? 'Semnat' : 'Semnează'}</span>;
  };

  const renderSignedDocument = () => (
    <SignedDocumentWrapper
      onClick={() =>
        history.push({
          pathname: '/document',
          state: { document },
        })}
      className="SignedDocumentWrapper"
    >
      <DocumentListItemWrapper className="DocumentListItemWrapper">
        <span className="document-name">{document.name}</span>

        {renderSignedBadge()}
      </DocumentListItemWrapper>
    </SignedDocumentWrapper>
  );

  const renderUnsignedDocument = () => (
    <UnsignedDocumentWrapper
      onClick={() =>
        history.push({
          pathname: '/document',
          state: { document },
        })}
      className="UnsignedDocumentWrapper"
    >
      <DocumentListItemWrapper className="DocumentListItemWrapper">
        <span className="document-name">{document.name}</span>
        {renderSignedBadge()}
      </DocumentListItemWrapper>
    </UnsignedDocumentWrapper>
  );

  if (onlyIcon) {
    return (
      <div
        onClick={() =>
          history.push({
            pathname: '/document',
            state: { document, onlyViewMode },
          })}
      >
        PDF
      </div>
    );
  }
  if (onlyViewMode) {
    return (
      <UnsignedDocumentWrapper
        onClick={() =>
          history.push({
            pathname: '/document',
            state: { document, onlyViewMode },
          })}
        className="UnsignedDocumentWrapper"
      >
        <DocumentListItemWrapper className="DocumentListItemWrapper">
          <span className="document-name">{document.name}</span>
        </DocumentListItemWrapper>
      </UnsignedDocumentWrapper>
    );
  }
  return document.signature ? renderSignedDocument() : renderUnsignedDocument();
}
