/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { IdcardOutlined } from "@ant-design/icons";
import { AppContext } from "../../context/app.context";

import cariconpng from "../../assets/gif/car-icon.png";
import documenteleicon from "../../assets/gif/documentelemele-icon.png";

import notificationicon from "../../assets/gif/notifications.png";
import { useHistory } from "react-router-dom";
import { ListItem } from "../../components";
import {
  ButtonsWrapper,
  DashboardPageWrapper,
  IconNumberWrapper,
  TitleWrapper,
  ImgWrapper,
  TitleNameWrapper,
  ContainerWrapper,
  IconNotificationNumberWrapper,
} from "./dashboard.styled";

export default function DashboardPage() {
  const { user, getTasks, tasks, notifications } = useContext(AppContext);
  const [tasksNumber, setTasksNumber] = useState(0);
  const [notificatioNumber, setNotificationNumber] = useState(0);
  const [selectedOption, setSelectedOption] = useState(0);

  // Order by date and reverse array

  useEffect(() => {
    getTasks(user.jwt);
  }, []);

  useEffect(() => {
    if (tasks) {
      setTasksNumber([...tasks.STATE_NEW, ...tasks.STATE_IN_PROGRESS, ...tasks.STATE_REJECTED, ...tasks.STATE_APPROVED].length);
    }
  }, [tasks]);

  useEffect(() => {
    if (notifications) {
      setNotificationNumber(notifications.length);
    }
  }, [notifications]);

  const history = useHistory();
  return (
    <DashboardPageWrapper className="DashboardPageWrapper">
      <TitleWrapper>
        <TitleNameWrapper>Dashboard</TitleNameWrapper>
        <ContainerWrapper>
          <ImgWrapper src={notificationicon} />
          <IconNotificationNumberWrapper onClick={() => history.push("/notification")}> {notificatioNumber} </IconNotificationNumberWrapper>
        </ContainerWrapper>
      </TitleWrapper>
      <ButtonsWrapper>
        <ListItem
          className={selectedOption === "Solicitarile mele" ? "active" : ""}
          onClick={() => {
            if (selectedOption === "Solicitarile mele") {
              setSelectedOption(null);
            } else {
              setSelectedOption("Solicitarile mele");
            }
          }}
          title="Solicitarile mele"
          subtitle="Vezi toate solicitarile"
          icon={<IconNumberWrapper> {tasksNumber} </IconNumberWrapper>}
          action={() => history.push("/tasks")}
        />
        <ListItem
          title="Garajul meu"
          subtitle="Vezi toate masinile"
          icon={<img src={cariconpng} alt="car icon" />}
          action={() => history.push("/garage")}
        />
        <ListItem
          title="Documentele mele"
          subtitle="Vezi toate documentele"
          icon={<img src={documenteleicon} alt="document icon" />}
          action={() => history.push("/my-documents")}
        />

        {/* <ListItem
					disabled
					title="eCarte service"
					subtitle="eCard-ul tau"
					icon={<img src={serviceicon} />}
					action={() => history.push('/eCard')}
				/> */}

        {window.location.hostname === "localhost" && (
          <ListItem
            title="Developer room"
            subtitle="Doar pentru developeri"
            icon={<IdcardOutlined />}
            action={() => history.push("/developer-room")}
          />
        )}
      </ButtonsWrapper>
    </DashboardPageWrapper>
  );
}
