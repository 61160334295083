/* eslint-disable react-hooks/exhaustive-deps */
import { message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Button, DatePickerWeb, HeaderSteps, InputWeb, Loader, SelectRadio, SelectWeb, Uploader 
} from '../../../../components';
import { RcaSubPageWrapper, RcaSubPageContent } from './rca.style';
import { AppContext } from '../../../../context/app.context';

export default function RcaSubpage() {
  const {
    user, API_URL, getUserDocuments, saveUserDocuments, selectedCarFromGarage 
  } = useContext(AppContext);

  const [pageLoaded, setPageLoaded] = useState(false);
  const [document, setDocument] = useState(null);

  const [serie, setSerie] = useState('');
  const [numar, setNumar] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [alert, setAlert] = useState(false);
  const [selectedAsigurator, setSelectedAsigurator] = useState(null);

  const [asiguratori, setAsiguratori] = useState([]);
  const history = useHistory();
  const fetchDocument = async () => {
    const res = await getUserDocuments('rca', selectedCarFromGarage.id);

    if (res.error) {
      return;
    }

    res.data.document && setDocument(res.data.document);

    setAlert(res.data.alert);
    setStartDate(res.data.start_date);
    setEndDate(res.data.end_date);
    setNumar(res.data.numar);
    setSerie(res.data.serie);
    setSelectedAsigurator(res.data.asigurator);

    setPageLoaded(true);
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_URL}/task/get-insurer`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setAsiguratori(response.data);
      })
      .then(() => fetchDocument())
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
      });
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: 'rca',
      id_user: user.user.id,
      document,
      serie,
      numar,
      start_date: startDate,
      end_date: endDate,
      asigurator: selectedAsigurator,
      alert,
      car_id: selectedCarFromGarage.id,
    });

    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }

  return (
    <RcaSubPageWrapper className="RcaSubPageWrapper">
      <HeaderSteps title="Rca" subtitle={`Mașină ${selectedCarFromGarage.registration_number}`} />

      <RcaSubPageContent className="RcaSubPageContent">
        <Uploader label="Document RCA" key={document} document={document} setDocument={setDocument} />

        <InputWeb allowClear placeholder="Introdu serie RCA" label="Serie RCA:" text={serie} onChangeText={setSerie} />
        <InputWeb allowClear placeholder="Introdu număr RCA" label="Număr RCA:" text={numar} onChangeText={setNumar} />

        <DatePickerWeb label="Dată început:" value={startDate} setValue={setStartDate} />
        <DatePickerWeb label="Dată expirare:" value={endDate} setValue={setEndDate} />

        <SelectWeb
          label="*Companie de asigurări"
          items={asiguratori}
          keyValue="id"
          keyName="name"
          onTextChange={(e) => {}}
          defaultIndex={selectedAsigurator?.name}
          placeholder="Alege un asigurator"
          onItemSelect={(item) => {
            setSelectedAsigurator(item);
          }}
        />

        <SelectRadio
          label="Alertă"
          onTextChange={(e) => {}}
          keyValue="value"
          keyName="label"
          items={[
            { label: 'Da', value: true },
            { label: 'Nu', value: false },
          ]}
          defaultValue={alert}
          placeholder="Vrei să primești o alertă când expiră?"
          onItemSelect={(item) => {
            setAlert(item.value);
          }}
        />

        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </RcaSubPageContent>
    </RcaSubPageWrapper>
  );
}
