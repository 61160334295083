/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from "react";
import { HeaderSteps, Button, Uploader, InputWeb, SelectRadio, Loader, DatePickerWeb } from "../../../../components";
import { PermisSubPageWrapper, PermisSubPageContent } from "./permis.style";
import { AppContext } from "../../../../context/app.context";
import { useHistory } from "react-router-dom";

export default function PermisSubpage() {
  const { user, getUserDocuments, saveUserDocuments } = useContext(AppContext);

  const [document, setDocument] = useState(null);

  const [pageLoaded, setPageLoaded] = useState(false);

  const [serie, setSerie] = useState("");
  const [numar, setNumar] = useState("");
  const [endDate, setEndDate] = useState(null);

  const [alert, setAlert] = useState(false);
  const history = useHistory();
  const fetchDocument = async () => {
    const res = await getUserDocuments("permis");

    console.log(res.data);
    if (res.error) {
      return;
    }

    setDocument(res.data.document);

    setAlert(res.data.alert);
    setEndDate(res.data.end_date);
    setNumar(res.data.numar);
    setSerie(res.data.serie);

    setPageLoaded(true);
  };

  useEffect(() => {
    fetchDocument();
  }, []);

  const updateDocument = async () => {
    const res = await saveUserDocuments({
      type: "permis",
      id_user: user.user.id,
      document: document,
      serie: serie,
      numar: numar,
      end_date: endDate,
      alert: alert,
    });

    if (res.error === false) {
      history.goBack();
    }
  };

  if (!pageLoaded) {
    return <Loader />;
  }
  return (
    <PermisSubPageWrapper className="PermisSubPageWrapper">
      <HeaderSteps title="Permis" subtitle="Documentele mele" />

      <PermisSubPageContent className="PermisSubPageContent">
        <Uploader label="Permis" document={document} setDocument={setDocument} />

        <InputWeb allowClear placeholder="Introdu serie permis" label="Serie permis:" text={serie} onChangeText={setSerie} />
        <InputWeb allowClear placeholder="Introdu număr permis" label="Număr permis:" text={numar} onChangeText={setNumar} />

        <DatePickerWeb label="Dată expirare:" value={endDate} setValue={setEndDate} />

        <SelectRadio
          label="Alertă"
          onTextChange={(e) => {}}
          keyValue="value"
          keyName="label"
          items={[
            { label: "Da", value: true },
            { label: "Nu", value: false },
          ]}
          defaultValue={alert}
          placeholder="Vrei să primești o alertă când expiră?"
          onItemSelect={(item) => {
            setAlert(item.value);
          }}
        />

        <Button title="Salvează" className="primary" onClick={updateDocument} />
      </PermisSubPageContent>
    </PermisSubPageWrapper>
  );
}
