import React from 'react';
import { Progress } from 'antd';
import { useHistory } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {
  HeaderWrapper, IconWrapper, TitleWrapper, TitleTopWrapper, SubtitleWrapper, RightChildren 
} from './header-steps.style';
import { storageGet, storageSave } from '../../storage/localStorage';

const searchStep = (array, step) => {
  try {
    const x = array.find((x) => x.step === step);
    return x;
  } catch (e) {}
};

export default function HeaderSteps({
  rightChildren, title, subtitle, procent, step, setStep, template, setTemplate, onDoubleClickTitle 
}) {
  const history = useHistory();
  const customTemplate = storageGet('customTemplate');

  return (
    <HeaderWrapper className="HeaderWrapper">
      <IconWrapper
        className="IconWrapper"
        onClick={() => {
          if (step > 1) {
            setStep(step - 1);
            setTemplate(searchStep(customTemplate.form, step - 1));
            storageSave('currentStep', step - 1);
          }
          history.goBack();
        }}
      >
        <ArrowLeftOutlined />
      </IconWrapper>

      <TitleTopWrapper className="TitleTopWrapper">
        <TitleWrapper onDoubleClick={onDoubleClickTitle}>{title}</TitleWrapper>
        <SubtitleWrapper>{subtitle}</SubtitleWrapper>
        <Progress strokeColor="#10e4e4" style={{ marginTop: procent ? 20 : 0, opacity: procent ? 1 : 0 }} percent={procent?.toFixed(0)} />

        {rightChildren && <RightChildren className="RightChildren">{rightChildren}</RightChildren>}
      </TitleTopWrapper>
    </HeaderWrapper>
  );
}
