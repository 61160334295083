import styled from "styled-components";

export const TaskPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: #f2f2f2;

  .HeaderWrapper {
    z-index: 99999;
    position: sticky;
  }
`;

export const TaskPageContent = styled.div`
  padding: 32px 16px;

  .TableWrapper {
    margin-bottom: 20px;
  }
  .step-title {
    margin-bottom: 8px;
    display: block;
    font-weight: bold;
  }

  .ant-carousel .slick-list .slick-slide > div > div {
    height: 220px;
    border-radius: 20px;
    overflow: hidden;
  }
`;

export const Subheader = styled.div`
  display: flex;
  background: rgb(24, 23, 38);
  padding: 92px 32px 32px;
  margin: -92px 0px 14px;
  border-radius: 0px 0px 0px 50px;
  flex-direction: column;
  align-items: center;
  position: relative;

  .task-info {
    margin-top: 32px;
    color: rgb(255, 255, 255);
    font-weight: bold;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    span {
      color: #fff;
    }

    .status {
      margin-top: 12px;
      font-size: 12px;
      border-radius: 33px;
      background: #e27213;
      padding: 8px 32px;
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  img {
    border-radius: 4px;
    width: 100%;
  }

  .CommentLabel {
    position: absolute;
    background: #ffffff;
    border-radius: 0px 33px 33px 33px;
    padding: 2px 7px;
    border: 1px solid #9c9c9c;
  }

  .photo-type {
    height: 26px;
    position: absolute;
    left: 8px;
    top: 42px;
    font-size: 10px;
    background: #0000008c;
    padding: 0px 12px;
    border-radius: 33px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const BadPhotoOverlayWrapper = styled.div`
  background: #00000030;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  z-index: 995;

  h2 {
    margin-bottom: 16px;
    color: #fff;
    font-size: 19px;
  }

  button {
    background: #e27213;
    color: #000;
    width: 150px;
    height: 37px;
  }
`;

export const LabelWrapper = styled.span`
  position: absolute;
  top: 8px;
  left: 8px;
  z-index: 999;
  background: rgba(0, 0, 0, 0.5);
  color: rgb(255, 255, 255);
  padding: 2px 32px;
  border-radius: 33px;
  box-shadow: 0px 0px 20px -7px #000;
`;
