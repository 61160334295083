import { DeleteOutlined } from "@ant-design/icons";
import React, { useReducer } from "react";
import { DocumentListItem, ImagePreviewComponent } from "..";
import { CarouselWrapper } from "./multiple-images-preview.styled";

export default function MultipleImagesPreview({ style, disabled, title, label, photosList, onHandleSave }) {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return (
    <CarouselWrapper style={style} className="CarouselWrapper">
      {label && <label>{label}</label>}
      {photosList.map((item, index) => {
        if (item.type === "doc") {
          console.log(item);

          item.document.url = item.document.uri;
          return (
            <div style={{ position: "relative" }}>
              <DeleteOutlined
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  padding: 16,
                }}
                onClick={() => {
                  const filterArray = photosList.map((e) => {
                    if (e.label === item.label) {
                      return (e = {
                        label: e.label,
                        value: null,
                        type: "photo",
                      });
                    }

                    return e;
                  });

                  onHandleSave(filterArray);
                }}
              />
              <DocumentListItem document={item.document} onlyViewMode />
            </div>
          );
        }
        return (
          <ImagePreviewComponent
            title={item.label}
            multipleMode
            disabled={disabled}
            label={label}
            withPreview={false}
            setPhotoURI={(newPhoto) => {
              const futurePhotosList = photosList;
              futurePhotosList[index].value = {};
              futurePhotosList[index].value.base64 = newPhoto;
              onHandleSave(futurePhotosList);
              forceUpdate();
            }}
            photoURI={item.value?.base64 || null}
          />
        );
      })}
    </CarouselWrapper>
  );
}
