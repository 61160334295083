import styled from "styled-components";

export const EditCarPageWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
`;
export const EditCarPageContent = styled.div`
  padding: 24px 16px;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 20px;
`;
