import React from "react";

import { ButtonWrapper } from "./button.style";
import { Spin } from "antd";

export default function Button({ style, htmlType, title, disabled, className, isLoading, onClick }) {
  return (
    <ButtonWrapper
      style={style}
      htmlType={htmlType}
      onClick={disabled ? null : onClick}
      className={className ? `ButtonWrapper ${className} ${disabled || isLoading ? "disabled" : ""}` : "ButtonWrapper"}
    >
      {isLoading ? <Spin /> : title}
    </ButtonWrapper>
  );
}
