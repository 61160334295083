/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import { CloseOutlined } from "@ant-design/icons";
import { Loader, Button, MultipleImagesPreview, HeaderSteps, DocumentListItem } from "../../../../components";
import { AppContext } from "../../../../context/app.context";
import { searchStep, getTimeString, getDateString } from "../../../../utils";

import { PhotoWrapper, StepSummaryWrapper, StepSummaryContent } from "./step-summary.styled";
import { storageGet, storageSave } from "../../../../storage/localStorage";
import { ImageFullScreenWrapper, ImageFullScreenHeader, IconTitleWrapper } from "../../../../components/image-preview/image-preview.style";
import { CameraTitleWrapper } from "../../../../components/camara/camara.style";
import { TableWrapper, TableRow } from "../../../view-car/view-car.style";

export default function StepSummary() {
  const {
    user,
    setIsLoading,
    isLoading,
    futureTask,
    setCurrentMap2DObject,
    setFutureTask,
    initialFutureTask,
    API_URL,
    setCustomStepTemplate,
  } = useContext(AppContext);

  const history = useHistory();

  const currentMap2DObject = storageGet("currentMap2DObject");
  const customTemplate = storageGet("customTemplate");
  const [step, setStep] = useState(storageGet("currentStep"));
  const [template, setTemplate] = useState(searchStep(customTemplate.form, step));

  const [finalLoading, setFinalLoading] = useState(false);
  const [usedMap2DParts, setUsedMap2DParts] = useState([]);
  const [preparedCarParts, setPreparedCarParts] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [currentModalPhoto, setCurrentModalPhoto] = useState(null);

  useEffect(() => {
    if (currentMap2DObject) {
      checkUsedMap2DParts();
    }
  }, []);

  const createNewTask = (formData) => {
    setIsLoading(true);
    setFinalLoading(true);

    axios({
      method: "post",
      url: `${API_URL}/task/create`,
      data: JSON.stringify(formData),
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // Show toast
        message.success(`Task-ul ${response.data.message.task_id} a fost creat`);
        // Reset template
        setCustomStepTemplate(null);
        // Reset cupi2D
        setCurrentMap2DObject(null);

        // Navigate to main screen
        history.push("/general-actions");

        // Reset flux
        setFutureTask({ ...initialFutureTask });
        storageSave("futureTask", { ...initialFutureTask });
        // Remove loading animatnion
        setIsLoading(false);
        setFinalLoading(false);
      })
      .catch((response) => {
        // hand le error

        try {
          if (response.response.data.error) {
            message.error(response.response.data.message);
          }
        } catch (e) {
          message.error("Eroare 9691");
          console.log("Repsonse 1252:", response);
        }
        setIsLoading(false);
        setFinalLoading(false);
      });
  };

  const checkUsedMap2DParts = () => {
    const template = currentMap2DObject.templates;
    const futureUsedCarParts = template.filter((e) => e.photos && e.photos.length > 0);

    setPreparedCarParts(futureUsedCarParts);
    setUsedMap2DParts(futureUsedCarParts);
  };

  const prepareTaskRequest = async () => {
    // const futureCustomTemplate = JSON.parse(JSON.stringify(customTemplate));

    // futureCustomTemplate.form.map((e) => console.log(e));

    // //Easy hack
    const local_stepInsurance = JSON.parse(JSON.stringify(futureTask.stepInsurance));

    if (
      futureTask.stepInsurance &&
      futureTask.stepInsurance.insurance_policy_photo &&
      futureTask.stepInsurance.insurance_policy_photo.length > 0
    ) {
      futureTask.stepInsurance.insurance_policy_photo.map((e, i) => {
        if (e?.url || e?.uri) {
          local_stepInsurance.insurance_policy_photo[i] = e?.url || e?.uri;
        } else {
          local_stepInsurance.insurance_policy_photo[i] = null;
        }
      });
    }

    const formData = {
      source: "app",
      role: "client",
      task: {
        type: futureTask.type,
        task_id: futureTask?.task?.task_id || null,
        assignee_id: futureTask?.task?.assignee_id || 2,
        poweruser_id: futureTask?.task?.poweruser_id || 2,
        client_id: futureTask?.task?.client_id || Number(futureTask.client.id) || Number(user.user.id),
        inspector_id: futureTask?.task ? futureTask?.task?.inspector_id : null,
        car_id: Number(futureTask.car.id) || futureTask?.task?.car_id,
      },
      step_insurance: local_stepInsurance,
      custom_form: customTemplate,
      car: {
        parts: preparedCarParts,
      },
    };

    createNewTask(formData);
  };

  const renderCarDetails = () => (
    <>
      <label className="step-title">Detalii masina</label>
      <TableWrapper className="TableWrapper">
        <TableRow className="TableRow">
          <p>Marca masina:</p>
          <p>{futureTask.car.make}</p>
        </TableRow>

        <TableRow className="TableRow">
          <p>Model masina:</p>
          <p>{futureTask.car.model}</p>
        </TableRow>

        <TableRow className="TableRow">
          <p>Model an:</p>
          <p>{futureTask.car.model_year}</p>
        </TableRow>
      </TableWrapper>
    </>
  );

  // VIEW GENERTOR

  const renderText = (input) => {
    let inputValue;

    if (input.type === "date") {
      inputValue = input.value ? getDateString(input.value) : "Necompletat";
    } else if (input.type === "time") {
      console.log(input.value);
      inputValue = input.value ? getTimeString(input.value) : "Necompletat";
    } else {
      inputValue = input.value ? input.value : "Necompletat";
    }
    return (
      <TableRow className="TableRow" key={inputValue + input.label}>
        <label>{input.label}:</label>
        <span>{inputValue}</span>
      </TableRow>
    );
  };

  const renderSelect = (input) => {
    let inputValueName = input.value ? input.value[input.keyValue] : null;

    if (!inputValueName) {
      inputValueName = "Necompletat";
    } else {
      inputValueName = input.value[input.keyName];
    }

    return (
      <TableRow className="TableRow">
        <label>{input.label}:</label>
        <span>{inputValueName}</span>
      </TableRow>
    );
  };

  const renderRadio = (input = null) => {
    let inputValueName = input?.value[input.keyValue];

    inputValueName = inputValueName ? input.value[input.keyName] : "Necompletat";

    return (
      <TableRow className="TableRow">
        <p>{input.label}:</p>
        <p>{inputValueName}</p>
      </TableRow>
    );
  };

  const renderImage = (input) => (
    <TableRow className="TableRow">
      <label>{input.general_label}:</label>
      <span>
        {input.photos.map((photo, index) => (
          <PhotoWrapper
            className="PhotoWrapper"
            onClick={() => {
              if (photo.value) {
                setCurrentModalPhoto({ label: photo.label, value: photo.value?.base64 });
                setVisibleModal(true);
              }
            }}
          >
            {photo.value ? (
              <img
                alt={photo.label}
                src={photo.value?.base64 || null}
                style={{
                  width: 50,
                  borderRadius: 8,
                  height: 50,
                }}
              />
            ) : (
              "X"
            )}
          </PhotoWrapper>
        ))}
      </span>
    </TableRow>
  );

  const renderFileDoc = (input) => (
    <PhotoWrapper>
      <DocumentListItem document={input.file} onlyViewMode onlyIcon />
    </PhotoWrapper>
  );

  const renderFileImage = (input) => (
    <PhotoWrapper
      className="PhotoWrapper"
      onClick={() => {
        if (input?.file?.uri) {
          setCurrentModalPhoto({ label: input.label, value: input?.file?.url || input?.file?.uri });
          setVisibleModal(true);
        }
      }}
    >
      <img
        alt="Document"
        style={{
          width: 50,
          borderRadius: 8,
          height: 50,
        }}
        src={input?.file?.url || input?.file?.uri}
      />
    </PhotoWrapper>
  );

  const renderFileConditional = (input) => (
    <TableRow className="TableRow">
      {input.general_label && <label>{input.general_label}:</label>}

      <div>
        {!input.file && (
          <div
            style={{
              width: 50,
              height: 50,
              borderRadius: 8,
              backgroundColor: "#f2f2f2",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            X
          </div>
        )}

        {console.log(input)}

        {input.file?.type === "doc" || input.file?.type === "success" || input.file?.type === "application/pdf"
          ? renderFileDoc(input)
          : null}
        {input.file?.type === "photo" ||
        input.file?.type === "image" ||
        input.file?.type === "image/jpeg" ||
        input.file?.type === "image/png"
          ? renderFileImage(input)
          : null}
      </div>
    </TableRow>
  );

  const renderStepDetails = (step) => (
    <>
      <label className="step-title">{step.title}</label>
      <TableWrapper className="TableWrapper">
        {step.inputs.map((input) => {
          switch (input.type) {
            case "select":
              return renderSelect(input);
            case "radio":
              return renderRadio(input);
            case "photo":
              return renderImage(input);
            case "file":
              return renderFileConditional(input);
            default:
              return renderText(input);
          }
        })}
      </TableWrapper>
    </>
  );

  const renderInsuranceStep = () => (
    <>
      <label>1. Despre asigurator</label>
      <TableWrapper className="TableWrapper">
        <TableRow className="TableRow">
          <p>Tip asigurare:</p>
          <p>{futureTask.stepInsurance.insurance_type_label || "Necompletat"}</p>
        </TableRow>
        <TableRow className="TableRow">
          <p>Asigurator:</p>
          <p>{futureTask.stepInsurance.insurer_label || "Necompletat"}</p>
        </TableRow>
        <TableRow className="TableRow">
          <p>Numar polita:</p>
          <p>{futureTask.stepInsurance.numar_polita || "Necompletat"}</p>
        </TableRow>
        <TableRow className="TableRow">
          <p>Serie polita:</p>
          <p>{futureTask.stepInsurance.serie_polita || "Necompletat"}</p>
        </TableRow>
        <TableRow className="TableRow">
          <label>Polita:</label>
          <span>
            {futureTask?.stepInsurance?.insurance_policy_photo &&
              futureTask.stepInsurance.insurance_policy_photo.map((e) => renderFileConditional({ file: e }))}
          </span>
        </TableRow>
      </TableWrapper>
    </>
  );
  if (finalLoading) {
    return <Loader />;
  }
  return (
    <StepSummaryWrapper>
      <ImageFullScreenWrapper
        className="ImageFullScreenWrapper"
        style={{
          display: currentModalPhoto && visibleModal ? "flex" : "none",
        }}
      >
        <ImageFullScreenHeader className="ImageFullScreenHeader">
          <IconTitleWrapper className="IconTitleWrapper" onClick={() => setVisibleModal(false)}>
            <CloseOutlined />
          </IconTitleWrapper>
          {currentModalPhoto?.label && <CameraTitleWrapper className="CameraTitleWrapper">{currentModalPhoto.label}</CameraTitleWrapper>}
        </ImageFullScreenHeader>

        <img alt={`${currentModalPhoto?.label || "Modal selected photo"}`} src={currentModalPhoto?.value} />
      </ImageFullScreenWrapper>

      <HeaderSteps
        step={step}
        setStep={setStep}
        setTemplate={setTemplate}
        title={template && template.title}
        subtitle={template && template.subtitle}
        procent={(step * 100) / customTemplate.form.length}
      />

      <StepSummaryContent>
        {/* Step insurance */}
        {futureTask.type === "FNOL" && renderInsuranceStep()}
        {/* Custom form render */}
        {customTemplate.form.map((form) => {
          if (form.type === "custom") return renderStepDetails(form);
        })}
        {/* Car render */}
        {futureTask.car && renderCarDetails()}
        {/* Used map 2d */}
        {usedMap2DParts && usedMap2DParts.length !== 0 && (
          <>
            <label className="step-title">Poze piese</label>
            <TableWrapper className="TableWrapper">
              {usedMap2DParts.map((usedPart) => (
                <div style={{ marginBottom: 32 }}>
                  <MultipleImagesPreview disabled label={usedPart.description} photosList={usedPart.photos} />
                </div>
              ))}
            </TableWrapper>
          </>
        )}
        <Button
          className="primary"
          isLoading={isLoading}
          disabled={isLoading}
          onClick={() => {
            prepareTaskRequest();
          }}
          active
          title="Trimite cerere"
        />
      </StepSummaryContent>
    </StepSummaryWrapper>
  );
}
