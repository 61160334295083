import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterWrapper = styled.div`
  height: 64px;
`;

export const FooterLinkButton = styled(Link)`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    background: #f8f8f8;
  }
  span {
    margin-bottom: 4px;
  }
`;

export const FooterContentWrapper = styled.div`
  height: 64px;
  display: flex;
  width: 100%;
  z-index: 999;
  max-width: 700px;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #eee;
`;
