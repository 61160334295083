import styled from "styled-components";

export const StepCustomWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: #f1f0f2;
`;
export const SectionWrapper = styled.div`
  margin-bottom: 22px;
  width: 100%;
`;

export const ImagePreviewWrapper = styled.div`
  width: 150px;
  border-radius: 8px;
  margin-bottom: 32px;
`;

export const FormContent = styled.div`
  padding: 32px 15px;
  display: flex;
  flex-wrap: wrap;
`;
export const TimePickerWrapper = styled.div`
  background: #fff;
  border-radius: 20px;
  padding: 22px 42px 32px 42px;
  width: 100%;

  .ant-picker {
    height: 37px;
    border-radius: 33px;
  }
  label {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 16px;
  }
`;

export const CustomAlert = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  max-width: 700px;
  z-index: 9999;
  background: #00000060;
  backdrop-filter: blur(6px);

  .buttonsWrapper {
    display: flex;
    flex-direction: column;

    button {
      margin-bottom: 14px;
      border-radius: 20px;
      border: none;
      padding: 8px 0px;
    }
  }
  .wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 20px;

    h1 {
      text-align: center;
      font-size: 20px;
    }

    h2 {
      text-align: center;
      opacity: 0.6;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
`;
