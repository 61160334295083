import styled from "styled-components";

export const StepGeneralPhotosWrapper = styled.div`
  height: calc(100% - 64px);
  overflow: scroll;
  background: #f1f0f2;

  .submit_button {
    margin-top: 64px;
  }
  .ant-progress-bg {
    background: #10e4e4;
  }

  .ant-progress-inner {
    position: relative;
    display: inline-block;
    width: 110%;
    overflow: hidden;
    vertical-align: middle;
    background-color: #f5f5f5;
    border-radius: 100px;
    left: -40%;
    top: 60px;
  }

  .ant-progress-text {
    position: relative;
    top: 57px;
    left: -50px;
  }

  .ant-form-item {
    background: #fff;
    padding: 20px 40px 30px;
    border-radius: 20px;
    margin-bottom: 15px;
    input {
      border-radius: 19px;
      border: 1px solid #707070;
      font-size: 12px;
      line-height: 20px;
      height: 37px !important;
    }
    .ant-form-item-label {
      display: inline-block;
      width: 100%;
      margin-bottom: 5px;
      text-align: left;
    }
    .ant-select-selector {
      border-radius: 19px;
      border: 1px solid #707070;
      font-size: 12px;
      line-height: 20px;
      height: 39px !important;
    }
    &.submit_button {
      background: transparent;
      margin-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    label {
      color: #181726;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      height: auto;
      margin-bottom: 0px;
    }
  }
  button {
    &[type="button"] {
      margin-left: 15px;
      border-radius: 20px;
      font-size: 14px;
      height: 37px;
      background: #10e4e4;
      color: #181726;
      border: 0;
      &:hover {
        color: #10e4e4;
        background: #181726;
      }
    }
    &[disabled] {
      opacity: 0.35;
    }
  }
`;

export const ImagePreviewWrapper = styled.div`
  width: 300px;
  border-radius: 8px;
  margin-bottom: 32px;
  margin-left: 20px;
`;

export const ImagesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 32px 15px;
`;
export const LabelWrapper = styled.div`
  font-weight: bold;
`;

export const ButtonWrapper = styled.button`
  width: 80%;
  border-radius: 52px;
  margin-top: 32px;
  background: #10e4e4;
  color: #181726;
  font-size: 14px;
  line-height: 26px;
  border: 0;
  padding: 13px 0;
  display: inline-block;
  height: auto;
  position: relative;
  font-weight: 500;
  text-transform: uppercase;
  left: 10%;
`;
