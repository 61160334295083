import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../../context/app.context';
import {
  Button, SelectRadio, SelectWeb, InputWeb, HeaderSteps, Loader, Uploader 
} from '../../../../components';

import { StepInsuranceWrapper, StepInsuranceContent, SectionWrapper } from './step-insurance.styled';
import { storageSave, storageGet } from '../../../../storage/localStorage';

export default function StepInsurance() {
  const {
    futureTask, setFutureTask, user, API_URL, setCustomStepTemplate, getUserDocuments, preventChangeScreen, setPreventChangeScreen 
  } = useContext(AppContext);
  const [tipAsigurare, setTipAsigurare] = useState([]);
  const [asiguratori, setAsiguratori] = useState([]);
  // Form
  const [selectedAsigurare, setSelectedAsigurare] = useState(futureTask.stepInsurance.insurance_type || null);
  const [selectedAsigurare_label, setSelectedAsigurare_label] = useState(futureTask.stepInsurance.insurance_type_label || null);
  const [selectedAsigurator, setSelectedAsigurator] = useState(futureTask.stepInsurance.insurer || null);
  const [selectedAsigurator_label, setSelectedAsigurator_label] = useState(futureTask.stepInsurance.insurer_label || null);

  const [insurancePolicyPhoto, setInsurancePolicyPhoto] = useState(futureTask.stepInsurance.insurance_policy_photo || [null]);

  const [numar_polita, setNumar_polita] = useState(futureTask.stepInsurance.numar_polita || null);
  const [serie_polita, setSerie_polita] = useState(futureTask.stepInsurance.serie_polita || null);

  const history = useHistory();

  const onFinish = () => {
    setPreventChangeScreen(true);
     
    const dataForm = {
      insurance_policy_photo: insurancePolicyPhoto,
      insurance_type: selectedAsigurare,
      insurance_type_label: selectedAsigurare_label,
      insurer: selectedAsigurator,
      insurer_label: selectedAsigurator_label,
      serie_polita,
      // category: 'Documente',
      numar_polita,
    };
    dataForm.insurance_policy_photo = insurancePolicyPhoto;
    setFutureTask({ ...futureTask, stepInsurance: dataForm });

    history.push({ pathname: '/step-custom/2' });
    storageSave('futureTask', futureTask);
  };

  const checkForCustomStepPrecompleted = () => {
    if (!selectedAsigurare_label) {
      return null;
    }
    axios({
      method: 'get',
      url: `${API_URL}/form/get-form-default?type=${selectedAsigurare_label.toLowerCase()}&user_id=${user.user.id}&car_id=${
        futureTask.car.id
      }&insurer_id=${selectedAsigurator}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setCustomStepTemplate(response.data);
        storageSave('customTemplate', response.data);
        message.success('Flux precompletat');
      })
      .catch((response) => {
        // handle error
        message.error('Eroare in a precompleta flux-ul');
      });
  };

  const checkForCustomStep = ({ asiguratorID, type }) => {
    axios({
      method: 'get',
      url: `${API_URL}/form/get-form?type=${selectedAsigurare_label ? selectedAsigurare_label.toLowerCase() : type.toLowerCase()}&user_id=${
        user.user.id
      }&car_id=${futureTask.car.id}&insurer_id=${selectedAsigurator || asiguratorID}`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setCustomStepTemplate(response.data);
        storageSave('customTemplate', response.data);
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
      });
  };

  useEffect(() => {
    axios({
      method: 'get',
      url: `${API_URL}/task/get-insurance`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setTipAsigurare(response.data);
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
      });

    axios({
      method: 'get',
      url: `${API_URL}/task/get-insurer`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${user.jwt}`,
      },
    })
      .then((response) => {
        // handle success
        setAsiguratori(response.data);
      })
      .catch((response) => {
        // handle error
        try {
          message.error(response.response.data.message);
        } catch (e) {
          console.log('Repsonse 1252:', response);
        }
      });
  }, [API_URL, user.jwt]);

  if (tipAsigurare.length <= 0 || asiguratori.length <= 0) {
    return <Loader />;
  }

  const _handleCascoSelected = async () => {
    const res = await getUserDocuments('casco', futureTask.car.id);

    if (res.error) {
      return setInsurancePolicyPhoto([null, null, null]);
    }

    setSelectedAsigurator(res.data?.asigurator?.id);
    setSelectedAsigurator_label(res.data?.asigurator?.name);
    setInsurancePolicyPhoto([res.data.document, res.data.document1, res.data.document2]);
    setNumar_polita(res.data?.numar);
    setSerie_polita(res.data?.serie);

    checkForCustomStep({
      asiguratorID: res.data?.asigurator?.id,
      type: 'casco',
    });
  };

  const _handleRcaSelected = async () => {
    setNumar_polita('');
    setSerie_polita('');
    setInsurancePolicyPhoto([null]);
    setSelectedAsigurator(null);
    setSelectedAsigurator_label(null);
  };

  const canContinue = () => {
    if (!numar_polita || !serie_polita || !selectedAsigurare || !selectedAsigurator || !storageGet('customTemplate')) {
      return false;
    }

    if (insurancePolicyPhoto.some((e) => e === undefined || e === null)) {
      return false;
    }
    return true;
  };

  return (
    <StepInsuranceWrapper className="StepInsuranceWrapper">
      <HeaderSteps step={1} title="Asigurare și asigurator" procent={0} onDoubleClickTitle={checkForCustomStepPrecompleted} />

      <StepInsuranceContent className="StepInsuranceContent">
        <SectionWrapper className="SectionWrapper">
          <SelectRadio
            label="*Tip asigurare"
            onTextChange={(e) => {}}
            keyValue="id"
            keyName="name"
            items={tipAsigurare}
            defaultValue={selectedAsigurare}
            placeholder="Alege un tip de asigurare"
            onItemSelect={(item) => {
              setSelectedAsigurare(item.id);
              setSelectedAsigurare_label(item.name);

              if (item.name.toLowerCase() === 'casco') {
                _handleCascoSelected();
              }
              if (item.name.toLowerCase() === 'rca') {
                _handleRcaSelected();
              }
            }}
          />
        </SectionWrapper>

        <SectionWrapper className="SectionWrapper">
          <SelectWeb
            key={selectedAsigurator_label}
            label="*Companie asigurări"
            items={asiguratori}
            keyValue="id"
            keyName="name"
            onTextChange={(e) => {}}
            defaultIndex={selectedAsigurator_label || null}
            placeholder="Alege un asigurator"
            onItemSelect={(item) => {
              setSelectedAsigurator(item.id);
              setSelectedAsigurator_label(item.name);
              checkForCustomStep(item.id);
            }}
          />
        </SectionWrapper>

        <SectionWrapper className="SectionWrapper">
          <InputWeb
            allowClear
            label="*Număr poliță asigurare vinovat"
            autoCapitalize="characters"
            placeholder="Exemplu: 10xxxxxx"
            text={numar_polita}
            onChangeText={(e) => {
              setNumar_polita(e);
            }}
          />
        </SectionWrapper>

        <SectionWrapper className="SectionWrapper">
          <InputWeb
            allowClear
            label="*Serie poliță asigurare vinovat"
            autoCapitalize="characters"
            placeholder="JH4DB7560SS00XXXX"
            text={serie_polita}
            onChangeText={(e) => {
              setSerie_polita(e);
            }}
          />
        </SectionWrapper>

        <SectionWrapper className="SectionWrapper">
          {selectedAsigurare && (
            <>
              {insurancePolicyPhoto.map((file, index) => (
                <Uploader
                  style={{ marginBottom: 20 }}
                  enableUpload
                  setDocument={(e) => {
                    const futureInsurance = [...insurancePolicyPhoto];
                    futureInsurance[index] = e;
                    setInsurancePolicyPhoto(futureInsurance);
                  }}
                  document={file}
                  label={`* Poliță asigurare - pagina ${index + 1}`}
                />
              ))}
            </>
          )}
        </SectionWrapper>
        <Button className="primary" disabled={!canContinue()} onClick={onFinish} title="Urmatorul pas" />
      </StepInsuranceContent>
    </StepInsuranceWrapper>
  );
}
