import React from "react";
import {
  TaskWrapper,
  InfoTop,
  CarNumberWrapper,
  DateWrapper,
  StateWrapper,
  MakeWrapper,
  InfoWrapper,
  StateWrapperRejected,
  StateWrapperNew,
  StateWrapperApproved,
  Information,
} from "./task.style";

import { useHistory } from "react-router-dom";
export default function Task(props) {
  let history = useHistory();
  const { task } = props;
  const { car_photo, task_id, car_make, car_registration_number, created_at, task_status, task_type } = task;

  const getDate = () => {
    const date = created_at.slice(0, 10);
    const dateArray = date.split("-");

    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];

    return `${day}-${month}-${year}`;
  };
  const renderStatusCode = () => {
    if (task_status.code === "STATE_REJECTED") {
      return <StateWrapperRejected className="StateWrapperRejected">{task_status.name}</StateWrapperRejected>;
    }
    if (task_status.code === "STATE_NEW") {
      return <StateWrapperNew className="StateWrapperNew">{task_status.name}</StateWrapperNew>;
    }
    if (task_status.code === "STATE_APPROVED") {
      return <StateWrapperApproved className="StateWrapperApproved">{task_status.name}</StateWrapperApproved>;
    }

    return <StateWrapper className="StateWrapper">{task_status.name}</StateWrapper>;
  };

  const renderTask = () => (
    <TaskWrapper
      className={`TaskWrapper ${task_status.name.replace(" ", "-")}`}
      onClick={() => history.push({ pathname: "/task", search: `?id=${task_id}` })}
    >
      <InfoWrapper className="InfoWrapper">
        <InfoTop className="InfoTop">
          <CarNumberWrapper className="CarNumberWrapper">{car_registration_number}</CarNumberWrapper>
          {renderStatusCode()}
        </InfoTop>
        <Information className="InfoTop">
          <DateWrapper className="DateWrapper">{getDate()}</DateWrapper>
        </Information>
        <Information className="InfoTop">
          <MakeWrapper className="MakeWrapper">{car_make}</MakeWrapper>
        </Information>
        <Information className="InfoTop">
          {task_type} | # {task_id}
        </Information>

        {car_photo && <img alt="car" style={{ width: 80, height: 60, position: "absolute", right: 16, bottom: 0 }} src={car_photo} />}
      </InfoWrapper>
    </TaskWrapper>
  );

  return renderTask();
}
