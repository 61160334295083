/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo, createContext, useState 
} from 'react';

// CONTEXT ===================================
const ComponentsContext = createContext();
function ComponentsProvider(props) {
  const [componentLevel0, setComponentLevel0] = useState(null);
  
  const store = {
    componentLevel0, setComponentLevel0
  };
  
  const storeForProvider = useMemo(() => store, [store]);
  return <ComponentsContext.Provider value={storeForProvider}>{props.children}</ComponentsContext.Provider>;
}
  
export { ComponentsContext };
export default ComponentsProvider;
