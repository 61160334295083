import styled from "styled-components";

export const HeaderWrapper = styled.div`
  background: white;
  width: 100%;
  display: flex;
  border-radius: 0px 0px 0px 50px;
  height: auto;
  padding: 20px;

  .ant-progress-text {
    color: #181726;
    font-weight: bold;
  }
`;

export const IconWrapper = styled.div`
  color: #757575;
  position: relative;
  width: auto;
  float: left;
  line-height: 28px;
`;

export const TitleTopWrapper = styled.div`
  width: 100%;
  margin-left: 15px;
  position: relative;
`;

export const TitleWrapper = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: #181726;
`;
export const SubtitleWrapper = styled.p`
  font-size: 12px;
  line-height: 18px;
  color: #464646;
  margin: 0 auto;
  padding: 0;
  width: 100%;
`;
export const RightChildren = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  img {
    width: 50px;
  }
`;
