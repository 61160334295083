import styled from 'styled-components';

export const AskForRegisterWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #00000014;
    z-index: 99999;
    height: 100%;
    backdrop-filter: blur(5px);
    padding: 16px;
`;
export const AskForRegisterContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:column;
    flex-direction: column;
    background: white;
    max-width: 700px;
    padding: 32px;
    border-radius: 20px;

    .checkbox-wrapper {
        padding:16px 0px;
    }
    .InputWrapper {
        padding : 0;
        margin-bottom:16px;
    }

    .buttons-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-top: 16px;


        button {
            border:none;
            background:none;
            padding:2px 16px;
            border-radius:20px;
        }

        .primary {
            background:#10e4e4;

            &:disabled {
                background:#eee;
            }
        }
    }

}`;
