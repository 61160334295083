import styled from "styled-components";

export const ImageFullScreenWrapper = styled.div`
  height: calc(100% - 64px);
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  background: #101010;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-height: calc(100% - 64px);
    width: 100%;
  }
`;

export const ImageFullScreenHeader = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  backdrop-filter: blur(61px);
  background: #00000017;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

export const ImageFullScreenActionWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 32px;
  max-width: 380px;

  .retake-photo {
    margin-top: 18px;
    background: #fff;
    color: #000;
  }
`;

export const ImageThumbnailWrapper = styled.div`
  padding: 32px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  border: 1px dashed #ddd;
  overflow: hidden;
  position: relative;
  max-height: 122px;

  .anticon {
    font-size: 27px;
  }

  label {
    font-size: 12px;
    color: #767676;
    margin: 20px 0px 0px 0px;
    margin-bottom: 0 !important;
  }

  .ImageTitle {
    position: absolute;
    top: 8px;
    left: 8px;
    background: #18172680;
    border-radius: 33px;
    padding: 4px 16px;
    color: #fff;
  }
`;

export const ImageWrapper = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 32px;
  border-radius: 16px;
  width: 100%;

  .title {
    font-size: 14px;
    font-weight: 500;
    display: block;
    margin-bottom: 16px;
  }

  &.multiple {
    padding: 0px;
  }
`;

export const IconTitleWrapper = styled.span`
  border-radius: 50%;
  background: #ffffff2b;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;

  .anticon {
    color: white !important;
  }
`;

export const CameraTitleWrapper = styled.span`
  position: absolute;
  left: 100px;
  color: white !important;
`;
