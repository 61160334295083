import React, { useContext, useState } from 'react';
import { Avatar, Modal } from 'antd';
import { UserOutlined, EditOutlined } from '@ant-design/icons';
import { AppContext } from '../../../../context/app.context';
import {
  ProfilePageWrapper, ImageUpload, ContentWrapper, IconProfileWrapper, ProfileInfoWrapper, LogOutWrapper 
} from './profile.style';
import { Button, InputWeb, SelectWeb } from '../../../../components';

export default function Profile() {
  const {
    user, updateProfile, isLoading, signout, regions, deleteAccount
  } = useContext(AppContext);

  const [profileImage, setProfileImage] = useState(null);

  const [email, setEmail] = useState(user.user.email);
  const [firstname, setFirstname] = useState(user.user.firstname);
  const [lastname, setLastname] = useState(user.user.lastname);
  const [phone, setPhone] = useState(user.user.phone);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  // Adress
  const [street, setStreet] = useState(user.profile.street || '');
  const [bloc, setBloc] = useState(user.profile.bloc || '');
  const [numar, setNumar] = useState(user.profile.numar || '');
  const [scara, setScara] = useState(user.profile.scara || '');
  const [ap, setAp] = useState(user.profile.ap || '');
  const [etaj, setEtaj] = useState(user.profile.etaj || '');
  const [region, setRegion] = useState(user.profile.county || null);
  const [location, setLocation] = useState(user.profile.location || null);

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deletePass, setDeletePass] = useState('');
  const onFinish = () => {
    updateProfile({
      email,
      firstName: firstname,
      lastName: lastname,
      phone,
      password,
      repeatPassword: confirmPassword,
      avatar: profileImage,
      county: region,
      location,
      street,
      bloc,
      numar,
      scara,
      ap,
      etaj,
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      const file = event.target.files[0];
      reader.onloadend = () => {
        setProfileImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <ProfilePageWrapper>
      <ProfileInfoWrapper>
        <LogOutWrapper
          className="LogoutClass"
          onClick={() => {
            signout();
          }}
        >
          LOGOUT
        </LogOutWrapper>
        <Avatar src={profileImage || user.profile.avatar} size={75} icon={<UserOutlined />} />
        <span className="LastNameUserClass">
          Salut,
          {' '}
          {user.user.lastname}
          !
        </span>
        <span className="RoleUserClass">{user.role}</span>
        <ImageUpload>
          <label htmlFor="file-input">
            <IconProfileWrapper>
              <EditOutlined />
              {' '}
            </IconProfileWrapper>
          </label>
          <input id="file-input" onChange={onImageChange} type="file" name="file" accept="image/*" capture="camera" />
        </ImageUpload>
      </ProfileInfoWrapper>
      <ContentWrapper className="ContentWrapper">
        <h2>General</h2>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 20,
            overflow: 'hidden',
            marginBottom: 32,
          }}
        >
          <InputWeb allowClear placeholder="Popescu" label="Nume" text={firstname} onChangeText={setFirstname} />
          <InputWeb allowClear placeholder="Ion" label="Prenume" text={lastname} onChangeText={setLastname} />
          <InputWeb allowClear placeholder="0721421531" label="Numar telefon" text={phone} onChangeText={setPhone} />
          <InputWeb
            allowClear
            placeholder="ion.popescu@gmail.com"
            label="Email"
            text={email}
            keyboardType="email-address"
            onChangeText={setEmail}
          />

          <div style={{ marginBottom: 32 }}>
            <InputWeb allowClear placeholder="Parola" label="Parola" text={password} onChangeText={setPassword} />

            {password ? (
              <InputWeb
                allowClear
                placeholder="Confirmă parola"
                label="Confirmă parola"
                text={confirmPassword}
                onChangeText={setConfirmPassword}
              />
            ) : null}
          </div>
        </div>

        {/* Adress */}
        <h2>Adresă</h2>
        <div
          style={{
            backgroundColor: 'white',
            borderRadius: 20,
            overflow: 'hidden',
            marginBottom: 32,
            flexDirection: 'row',
            flexWrap: 'wrap',
            display: 'flex',
          }}
        >
          <SelectWeb
            style={{ width: '100%' }}
            label="Alege un județ"
            keyValue="id"
            keyName="name"
            items={regions || []}
            defaultIndex={region}
            placeholder="Alege un județ"
            onTextChange={(e) => console.log(e)}
            onItemSelect={(e) => {
              setRegion(e.name);
              // setBodyType(e.id);
            }}
          />
          <InputWeb
            style={{ width: '100%' }}
            allowClear
            placeholder="Agnita"
            label="Localitate"
            text={location}
            onChangeText={setLocation}
          />
          <InputWeb style={{ width: '100%' }} allowClear placeholder="Fabricii" label="Stradă" text={street} onChangeText={setStreet} />
          <InputWeb style={{ width: '50%' }} allowClear placeholder="38" label="Bloc" text={bloc} onChangeText={setBloc} />
          <InputWeb style={{ width: '50%' }} allowClear placeholder="38" label="Număr" text={numar} onChangeText={setNumar} />
          <InputWeb style={{ width: '50%' }} allowClear placeholder="A" label="Scară" text={scara} onChangeText={setScara} />
          <InputWeb style={{ width: '50%' }} allowClear placeholder="12" label="Apartament" text={ap} onChangeText={setAp} />
          <InputWeb style={{ width: '50%' }} allowClear placeholder="3" label="Etaj" text={etaj} onChangeText={setEtaj} />
        </div>

        <Button
          onClick={() => {
            onFinish();
          }}
          className="primary"
          disabled={isLoading}
          isLoading={isLoading}
          title="Salvează Modificari"
        />

        <Modal
          maskClosable={false}
          footer={null}
          title="Sterge cont"
          visible={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
        >
          <p>Ai solicitat stergerea contului</p>
          <p>  
            {user.user.email}
          </p>
          <p>Pentru confirmare te rugam să introduci parola in casuta de mai jos.</p>

          <InputWeb
            style={{ padding: 0 }}
            allowClear
            placeholder="*******"
            label="Parola"
            text={deletePass}
            autoCapitalize="characters"
            onChangeText={(e) => setDeletePass(e)}
          />

          <Button
            onClick={async () => {
              const res = await deleteAccount({
                password: deletePass
              });
            }}
            
            style={{
              background: '#ff6b6b', marginTop: 20, border: 'none', color: 'white' 
            }}
            className="primary danger"
            disabled={isLoading}
            isLoading={isLoading}
            title="Sterge contul"
          />
        </Modal>

        {!user.user.email.includes('guest_') && (
        <Button
          onClick={() => {
            setDeleteModalVisible(true);
          }}
          className="danger"
          disabled={isLoading}
          isLoading={isLoading}
          title="Sterge contul"
        />
        )}
      </ContentWrapper>
    </ProfilePageWrapper>
  );
}
