import React, { useContext } from "react";
import { ListItem } from "../../components";

import daunanouaiconpng from "../../assets/gif/daunanoua-icon.png";
import newinsiconpng from "../../assets/gif/new-insp-icon.png";
import reviziiiconpng from "../../assets/gif/revizii-icon.png";
import pieseiconpng from "../../assets/gif/piese-icon.png";
import serviciiiconpng from "../../assets/gif/serv-icn.png";
import utileiconpng from "../../assets/gif/utile-icon.png";
import hlpiconpng from "../../assets/gif/help-icn.png";
import { AppContext } from "../../context/app.context";
import { useHistory } from "react-router-dom";
import { GeneralActionsWrapper, ButtonsWrapper } from "./general-actions.style";

export default function GeneralActionsPage() {
  const { user, futureTask, setFutureTask, initialFutureTask, setCurrentMap2DObject } = useContext(AppContext);
  const history = useHistory();

  console.log(futureTask);
  return (
    <GeneralActionsWrapper>
      <ButtonsWrapper>
        <ListItem
          title="Notificare dauna"
          subtitle="Declara o notificare noua"
          icon={<img src={daunanouaiconpng} alt="plus" />}
          action={() => {
            if (futureTask.client.email && window.confirm("Un task este deja in curs, vrei sa incepi task nou?")) {
              setFutureTask({
                ...initialFutureTask,
                client: user.user,
                type: "FNOL",
              });
              setCurrentMap2DObject(null);
            } else {
              setFutureTask({
                ...futureTask,
                client: user.user,
                type: "FNOL",
              });
            }
            history.push("/new-damage");
          }}
        />

        {user.role === "inspector" && (
          <ListItem
            title="Inspectie de risc"
            subtitle="Pentru validare polita CASCO"
            icon={<img src={newinsiconpng} alt="plus" />}
            action={() => {
              setFutureTask({ ...futureTask, inspector: user.user, type: "IR" });
              history.push("/step-pick-client");
            }}
          />
        )}

        <ListItem
          disabled
          title="Revizii"
          subtitle="Pachete manopera si servicii"
          icon={<img src={reviziiiconpng} alt="pachet servicii" />}
          action={() => {
            setFutureTask({ ...futureTask, client: user.user });
            history.push("/pick-car");
          }}
        />

        <ListItem
          disabled
          title="Piese"
          subtitle="Comanda piese auto"
          icon={<img src={pieseiconpng} alt="piesa auto" />}
          action={() => {
            setFutureTask({ ...futureTask, client: user.user });
            history.push("/pick-car");
          }}
        />
        <ListItem
          disabled
          title="Service"
          subtitle="Reparatii mecanice, roti, detailing"
          icon={<img src={serviciiiconpng} alt="service" />}
          action={() => {
            setFutureTask({ ...futureTask, client: user.user });
            history.push("/pick-car");
          }}
        />
        <ListItem
          disabled
          title="Utile"
          subtitle="ITP, taxe, alte servicii"
          icon={<img src={utileiconpng} alt="utile" />}
          action={() => {
            setFutureTask({ ...futureTask, client: user.user });
            history.push("/pick-car");
          }}
        />
        <ListItem
          disabled
          title="Ajutor"
          subtitle="Cum? Unde? Ce?"
          icon={<img src={hlpiconpng} alt="ajutor" />}
          action={() => {
            setFutureTask({ ...futureTask, client: user.user });
            history.push("/pick-car");
          }}
        />
      </ButtonsWrapper>
    </GeneralActionsWrapper>
  );
}
