import styled from "styled-components";

export const ButtonWrapper = styled.button`
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 33px;
  padding: 2px 30px 0;
  color: #10e4e4;
  transition: 0.2s;
  outline: none;
  border: none;
  background: none;
  width: 100%;
  position: relative;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.background}40;
  }

  .ant-spin-dot-item {
    background: #10e4e4;
  }
  &:active {
    transform: scale(0.9);
  }

  &.link {
    border-radius: 33px;
    background: #fafafa;
    color: #232323;
    height: 32px;
    font-weight: 300;
  }

  &.link-active {
    background: #10e4e4;
    color: #fff;
  }
  &.link-danger {
    background: red;
    color: #fff;
  }

  &.primary {
    background: #10e4e4;
    color: #181726;
    border: 1px solid #10e4e4;
    &:hover {
      border: 1px solid #10c9c9;
      background: #10c9c9;
    }
  }
  &.primary-transparent {
    background: #10e4e440;
    color: #fff;
    border: none;
    &:hover {
      background: #10c9c940;
    }
  }

  &.disabled {
    border: 1px solid #dddddd;
    background: #dddddd;
    color: #767676;
    &:hover {
      background: #dddddd;
      border: 1px solid #dddddd;
      cursor: no-drop;
    }

    &:active {
      background: #ddd;
      border: 1px solid #ddd;
      transform: scale(1);
    }
  }
`;
