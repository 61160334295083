import styled from 'styled-components';

export const ProtectedPageWrapper = styled.div`
  padding: 32px;

  .LogoWrapper {
    width: 50%;
    margin: 0 auto 50px auto;
  }
`;
