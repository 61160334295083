import styled from "styled-components";

export const CarListItemWrapper = styled.div`
  border: 1px solid #eee;
  background: #fff;
  font-size: 14px;
  line-height: 17px;
  color: #241332;
  font-weight: 400;
  padding: 8px;
  border-radius: 20px;
  margin-bottom: 16px;
  transition: 0.3s;
`;
export const ContainerWrapper = styled.div`
  position: relative;
  overflow: hidden;

  .active {
    box-shadow: 0px 0px 20px -10px #fc7900;
    border: 2px solid #fc7900;
  }

  .car-image {
    width: 150px;
    position: absolute;
    top: 30px;
    right: 30px;
  }
`;

export const CarInfo = styled.div`
  padding: 8px;
  justify-content: space-between;
  display: flex;
  margin-top: 8px;
`;

export const CarMake = styled.div`
  font-weight: 700;
  padding-left: 8px;
  padding-top: 7px;
`;

export const Information = styled.div`
  padding-left: 8px;
  padding-top: 7px;
  img {
    position: relative;
    left: 10px;
  }
`;

export const CarNumberWrapper = styled.div`
  background: #fff;
  border-radius: 2px;
  border: 1px solid #111;
  color: #111;
  padding: 0px 4px 0px 0px;
  display: flex;
  &::before {
    content: "RO";
    background: #10e4e4;
    display: flex;
    font-size: 7px;
    width: 12px;
    color: #fff;
    justify-content: center;
    align-items: flex-end;
    margin-right: 4px;
  }
`;
